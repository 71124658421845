import {
  IDSComponent,
  IPresetPreview,
  PageAlternative,
} from '@wix/adi-core-santa-layer';

export class SiteLocation {
  constructor(page: IDSComponent, top: number) {
    this.page = page;
    this.top = top;
  }
  page?: IDSComponent;
  top?: number;
}

export class PresetPreviewResults {
  private _presetPreviews: IPresetPreview[] = [];
  private _pagePresetPreviews: PageAlternative[] = [];
  private _insertionLocation: SiteLocation = {};
  private _mode: 'section' | 'page' = 'section';

  constructor() {}

  get mode() {
    return this._mode;
  }

  get presetPreviews() {
    return this._presetPreviews;
  }

  get pagePresetPreviews() {
    return this._pagePresetPreviews;
  }

  get insertionLocation() {
    return this._insertionLocation;
  }

  setPresetPreviews(pp: IPresetPreview[]) {
    this._presetPreviews = pp;
    this._mode = 'section';
  }
  setPagePreviews(pagePreviews: PageAlternative[]) {
    this._pagePresetPreviews = pagePreviews;
    this._mode = 'page';
  }
  setLocationOnPage(location: SiteLocation) {
    this._insertionLocation = location;
  }
}

export const presetPreviewResults = new PresetPreviewResults();
