import * as React from 'react';
import classNames from 'classnames';
import { presetPreviewResults } from '../../../coreFacade/presetPreviewResults';
import {
  IPresetPreview,
  adiPaas,
  COLORATION_LEVEL,
  COLORATION_METHOD_TYPE,
} from '@wix/adi-core-santa-layer';
import { PresetPreview } from '../../../presetPreview/components/PresetPreview/PresetPreview';
import * as s from './PresetPreviewList.scss';

interface PresetPreviewListState {
  pagePresetIndex: number;
}

interface PresetPreviewListProps {
  onSelectSection?(isSection: boolean): void;
}

export class PresetPreviewList extends React.Component<
  PresetPreviewListProps,
  PresetPreviewListState
> {
  private selectedPresetPreview: IPresetPreview | null = null;

  constructor(props: PresetPreviewListProps) {
    super(props);

    this.state = { pagePresetIndex: 0 };
  }

  nextPage() {
    const pagePresetIndex =
      (this.state.pagePresetIndex + 1) %
      presetPreviewResults.pagePresetPreviews.length;

    this.setState({ pagePresetIndex });
  }

  get renderedCollection(): IPresetPreview[] {
    if (presetPreviewResults.mode === 'section') {
      return presetPreviewResults.presetPreviews;
    } else {
      return presetPreviewResults.pagePresetPreviews[this.state.pagePresetIndex]
        .presetPreviews;
    }
  }

  get renderedCollectionKit(): any {
    if (presetPreviewResults.mode === 'section') {
      return undefined as any;
    } else {
      return presetPreviewResults.pagePresetPreviews[this.state.pagePresetIndex]
        .kit;
    }
  }

  get renderedCollectionColorMode(): string {
    if (presetPreviewResults.mode === 'section') {
      return 'NA';
    } else {
      switch (
        presetPreviewResults.pagePresetPreviews[this.state.pagePresetIndex]
          .pageConfig.colorationMethod
      ) {
        case COLORATION_METHOD_TYPE.light:
          return 'Light';
        case COLORATION_METHOD_TYPE.dark:
          return 'Dark';
        case COLORATION_METHOD_TYPE.mix:
          return 'Mix';
        default:
          return 'NA';
      }
    }
  }

  get renderedCollectionColorLevel(): string {
    if (presetPreviewResults.mode === 'section') {
      return 'NA';
    } else {
      switch (
        presetPreviewResults.pagePresetPreviews[this.state.pagePresetIndex]
          .pageConfig.colorationLevel
      ) {
        case COLORATION_LEVEL.plain:
          return 'Plain';
        case COLORATION_LEVEL.tinted:
          return 'Tinted';
        case COLORATION_LEVEL.colorful:
          return 'Colorful';
        case COLORATION_LEVEL.vibrant:
          return 'Vibrant';
        default:
          return 'NA';
      }
    }
  }

  get presetMode() {
    return presetPreviewResults.mode;
  }

  get pagePresetPreviews() {
    return presetPreviewResults.pagePresetPreviews;
  }

  selectPreset = (presetPreview: IPresetPreview) => {
    this.selectedPresetPreview = presetPreview;
    if (presetPreviewResults.insertionLocation.page) {
      this.props.onSelectSection && this.props.onSelectSection(true);
      return adiPaas
        .selectSection(
          presetPreview,
          presetPreviewResults.insertionLocation.page,
          presetPreviewResults.insertionLocation.top,
        )
        .then(models => console.log('renderModels', models));
    }
  };

  selectPagePreset = () => {
    this.props.onSelectSection && this.props.onSelectSection(false);
    return adiPaas
      .selectPagePreset(
        presetPreviewResults.pagePresetPreviews[this.state.pagePresetIndex],
      )
      .then(models => console.log('renderModels', models));
  };

  isSelected(preview: IPresetPreview): boolean {
    return (
      preview.preset &&
      this.selectedPresetPreview &&
      this.selectedPresetPreview.preset &&
      this.selectedPresetPreview.preset.id === preview.preset.id
    );
  }

  onPresetPreviewBeforeRender(preview: IPresetPreview) {
    adiPaas.setConfigurationForSection(preview);
  }

  private getScale() {
    if (this.presetMode === 'page') {
      return 0.43;
    } else if (this.presetMode === 'section') {
      return 0.35;
    } else {
      return 1;
    }
  }

  private readonly getPlaygroundPresetPreview = (
    presetPreview: IPresetPreview,
  ) => {
    const key = presetPreview.preset && presetPreview.dummyPageId;
    const presetId = presetPreview.preset && presetPreview.preset.id;
    return (
      <div className="preview-section" key={key}>
        <div className="preview-section-container" id={`preset-id-${presetId}`}>
          <div
            className={classNames({
              previewSectionLoading: true,
              selected:
                this.isSelected(presetPreview) && this.presetMode === 'section',
            })}
          >
            <div className="preview-content">
              <PresetPreview
                presetPreview={presetPreview}
                kit={this.renderedCollectionKit}
                scale={this.getScale()}
                onBeforeRender={() =>
                  presetPreviewResults.mode === 'section' &&
                  this.onPresetPreviewBeforeRender(presetPreview)
                }
              />
            </div>
            {this.presetMode === 'section' && (
              <div
                className="preview-overlay"
                onClick={() => this.selectPreset(presetPreview)}
              >
                <div className="preview-overlay-title"> Add this section </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };

  render() {
    return (
      <div
        className={classNames('preview-list-wrap', s.root)}
        id="preview_list_wrap"
      >
        <div className="preview-list-container">
          {this.presetMode === 'page' && (
            <div style={{ height: '40px', marginTop: '-20px' }}>
              <span
                style={{
                  cursor: 'pointer',
                  borderRadius: '18px',
                  padding: '10px 23px',
                  marginRight: '6px',
                  backgroundColor: '#3899EC',
                  color: '#FFFFFF',
                }}
                className="add-page"
                onClick={() => this.selectPagePreset()}
              >
                {' '}
                Add this page{' '}
              </span>
              <span>
                Color Mode {this.renderedCollectionColorMode}, Level{' '}
                {this.renderedCollectionColorLevel}
              </span>
              <span style={{ float: 'right' }}>
                {`${this.state.pagePresetIndex + 1} out of ${
                  this.pagePresetPreviews.length
                } `}
                <span
                  style={{ cursor: 'pointer' }}
                  onClick={() => this.nextPage()}
                >
                  Next
                </span>
              </span>
            </div>
          )}
          <div
            className={classNames({
              previewList: true,
              page: this.presetMode === 'page',
            })}
          >
            {this.renderedCollection.map(col =>
              this.getPlaygroundPresetPreview(col),
            )}
          </div>
        </div>
      </div>
    );
  }
}
