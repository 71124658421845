import * as React from 'react';
import { mediaGallery } from '@wix/adi-core-santa-layer';
import { MediaItemTypes } from '@wix/adi-core-types';

interface Iboundary {
  height: number;
  width: number;
}

interface FreeStyleProps {
  boundary: Iboundary;
  value: any;
}

const getSlideDefaultStyle = (width, height): React.CSSProperties => ({
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  border: '2px solid #ffffff',
  boxShadow: '-2px 3px 10px 0 rgba(0,0,0,0.2)',
  position: 'absolute',
  width,
  height,
});

const slidesProperties: React.CSSProperties[] = [
  {
    top: '30%',
    left: '5%',
    transform: 'rotate(370deg)',
    transformOrigin: 'bottom right',
  },
  {
    top: '22%',
    left: '46%',
    transform: 'rotate(347deg)',
    transformOrigin: 'bottom right',
  },
  {
    top: '44%',
    left: '24%',
    transform: 'rotate(364deg)',
    transformOrigin: 'top right',
  },
  {
    top: '20%',
    left: '13%',
    transform: 'rotate(345deg)',
  },
  {
    top: '5%',
    left: '30%',
    transform: 'rotate(354deg)',
    transformOrigin: 'bottom right',
  },
];

const getBackgroundImage = (width, height, value, i: number) =>
  `url(${mediaGallery.itemToThumbnailUrl(value[i], width, height)})`;

const getSlides = (
  width: number,
  height: number,
  value: any,
): JSX.Element[] => {
  const defaultStyle: React.CSSProperties = getSlideDefaultStyle(width, height);

  return slidesProperties.map(
    (prop, i) =>
      value[i] &&
      value[i].mediaType === MediaItemTypes.Image && (
        <div
          style={{
            ...defaultStyle,
            ...prop,
            backgroundImage: getBackgroundImage(width, height, value, i),
          }}
        />
      ),
  );
};

const getContainerStyle = (boundary): React.CSSProperties => ({
  position: 'relative',
  overflow: 'hidden',
  height: boundary.height,
  width: boundary.width,
});

export const FreeStyle: React.FunctionComponent<FreeStyleProps> = ({
  boundary,
  value,
}) => {
  const width = 250;
  const height = 350;

  return (
    <div
      className="freestyle-media-layout-preview"
      style={getContainerStyle(boundary)}
    >
      {getSlides(width, height, value)}
    </div>
  );
};
