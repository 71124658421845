import * as $ from 'jquery';
import { removePx } from '../components/SinglePresetPreview/utils';

const LAST_PRESET_MAX_HEIGHT = 56 * 4;
const MOBILE_LAST_PRESET_MAX_HEIGHT = 175;
const MIN_SLIDES_FOR_SLIDESHOW_PRESET = 2;

export type Transform = (
  presetsElements: NodeListOf<Element>,
  $scope: ng.IScope,
) => void;

function makeHeaderTransparent(header: JQuery) {
  header.css({
    zIndex: 10,
    position: 'relative',
    background: 'none',
  });
}

function getMergedHeaderHeights(header: JQuery, mediaContainer: JQuery) {
  const headerHeight = header.height();
  const mediaHeightOld = mediaContainer.height();
  const mediaHeightNew = headerHeight + mediaHeightOld;
  return { headerHeight, mediaHeightOld, mediaHeightNew };
}

function stretchImage(
  mediaContainer: JQuery,
  mediaHeightOld: number,
  mediaHeightNew: number,
) {
  let imageUrl = mediaContainer.css('background-image');
  if (imageUrl) {
    imageUrl = imageUrl.replace(
      new RegExp(`([,/]h_)${mediaHeightOld}([,/])`),
      `$1${mediaHeightNew}$2`,
    );
    mediaContainer.css('background-image', imageUrl);
  }
}

function stretchVideo(mediaContainer: JQuery, mediaHeightNew: number) {
  const video = mediaContainer.children('video');
  if (video.length > 0) {
    video.height(mediaHeightNew);
  }
}

function stretchSlider(
  mediaContainer: JQuery,
  mediaHeightOld: number,
  mediaHeightNew: number,
) {
  if (mediaContainer.hasClass('slider-media-layout-preview')) {
    mediaContainer
      .children('.img')
      .each((i, img) =>
        stretchImage($(img) as any, mediaHeightOld, mediaHeightNew),
      );
  }
}

function stretchStripWithMediaBackground(
  strip: any,
  mediaBg: any,
  header: any,
) {
  const {
    headerHeight,
    mediaHeightOld,
    mediaHeightNew,
  } = getMergedHeaderHeights(header, mediaBg);
  stretchImage(mediaBg, mediaHeightOld, mediaHeightNew);
  mediaBg.css('height', mediaHeightNew);
  strip.css({
    marginTop: -headerHeight,
    paddingTop: headerHeight,
  });
}

function moveSiblingsDown($el: JQuery, offset: number) {
  $el.siblings().each((i, sibling: HTMLElement) => {
    if (sibling.style.top) {
      const $sibling = $(sibling);
      const top = removePx($sibling.css('top'));
      $sibling.css('top', `${top + offset}px`);
    }
  });
}

function stretchAndMoveStripElements(
  rootStrip: any,
  mediaStrip: any,
  header: any,
) {
  const {
    headerHeight,
    mediaHeightOld,
    mediaHeightNew,
  } = getMergedHeaderHeights(header, mediaStrip);

  stretchImage(mediaStrip, mediaHeightOld, mediaHeightNew);
  stretchVideo(mediaStrip, mediaHeightNew);
  stretchSlider(mediaStrip, mediaHeightOld, mediaHeightNew);

  const elementsToProcess = mediaStrip.add(
    mediaStrip.parentsUntil('.lg-container'),
  );
  elementsToProcess.each((i, el: HTMLElement) => {
    const $el = $(el);
    if (el.style.height) {
      const height = $el.height();
      $el.height(height + headerHeight);
    }

    const hasCalculatedPosition = !!el.style.top;
    if (hasCalculatedPosition) {
      moveSiblingsDown($el, headerHeight);
    }
  });
  rootStrip.css('margin-top', -headerHeight);
}

export const PresetsTransforms = {
  mergeHeader: (presetsElements_ => {
    const presetsElements = $(presetsElements_);
    const header = presetsElements.eq(0);
    const firstSection = presetsElements.eq(1);
    const firstSectionStrip = firstSection.find('.strip-preview');

    const mediaBg = firstSectionStrip.find('> .media-bg');
    const costToCostMedia = firstSectionStrip
      .find('.strip-media-layout-preview')
      .filter(
        (i, el) =>
          removePx(
            $(el)
              .parent()
              .css('top'),
          ) === 0,
      )
      .eq(0);

    makeHeaderTransparent(header as any);
    if (mediaBg.length > 0) {
      stretchStripWithMediaBackground(firstSectionStrip, mediaBg, header);
    } else {
      stretchAndMoveStripElements(firstSectionStrip, costToCostMedia, header);
    }
  }) as Transform,

  limitLastPresetHeight: (presetsElements_ => {
    const presetsElements = $(presetsElements_);
    const lastPresetStrip = presetsElements.last().find('.strip-preview');

    if (lastPresetStrip.length > 0) {
      const isMobile = lastPresetStrip.hasClass('strip-preview-mobile');
      const marginTop = removePx(lastPresetStrip.css('margin-top'));
      const lastPresetMaxHeight = isMobile
        ? MOBILE_LAST_PRESET_MAX_HEIGHT
        : LAST_PRESET_MAX_HEIGHT;
      const maxHeight =
        lastPresetMaxHeight + (marginTop < 0 ? Math.abs(marginTop) : 0);
      lastPresetStrip.css({
        maxHeight,
        boxSizing: 'content-box',
      });
    }
  }) as Transform,

  //NEED TO FIX SCOPE ISSUE FROM ANGULAR TO REACT
  slideShowPreset: ((presetsElements_, scope_) => {
    const presetsElements = $(presetsElements_);
    // const $scope = $(scope_);

    const preset = presetsElements.eq(0);
    const lgs = preset.find('.lg-container');
    const slides = lgs.filter(
      '[data-contentelementtype != "ListHeader"][data-contentelementtype != "ListFooter"]',
    );
    slides.wrapAll('<div class="slide-show-film"></div>');
    if (slides.length < MIN_SLIDES_FOR_SLIDESHOW_PRESET) {
      return;
    }
    const slideShowFilm = slides.parent();
    const arrows = preset.find('.slideshow-arrows');
    const paddingTop = slideShowFilm.height() / 2 - 40 + 'px';
    arrows.css('padding', paddingTop + ' 25px 0');
    let animationInProgress = false;
    const animateToNextSlide = () => {
      if (!animationInProgress || slideShowFilm.hasClass('-is-sliding')) {
        return;
      }
      slideShowFilm.addClass('-is-sliding');
      slideShowFilm.one('transitionend', () => {
        slideShowFilm.removeClass('-is-sliding');
        slideShowFilm.children(':first').appendTo(slideShowFilm as any);
        setTimeout(animateToNextSlide, 1000);
      });
    };
    const startAnimation = () => {
      animationInProgress = true;
      animateToNextSlide();
    };
    const stopAnimation = () => {
      animationInProgress = false;
    };

    slideShowFilm.on('preview-slide-show-start', startAnimation);
    slideShowFilm.on('preview-slide-show-stop', stopAnimation);
    // $scope.$on('$destroy', () => {
    //   slideShowFilm.off('preview-slide-show-start');
    //   slideShowFilm.off('preview-slide-show-stop');
    // });
  }) as Transform,
};
export type IPresetsTransforms = typeof PresetsTransforms;
export type TransformId = keyof IPresetsTransforms;
