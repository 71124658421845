import * as React from 'react';
import * as _ from 'lodash';
import classNames from 'classnames';
import { LayoutElement, mediaGallery } from '@wix/adi-core-santa-layer';
import { ElementSubTypes } from '@wix/adi-core-types';
import { ImageShadow } from '../ImageShadow/ImageShadow';
// import * as s from './Image.scss';

interface Iboundary {
  height: number;
  width: number;
}

interface ImageProps {
  boundary: Iboundary;
  layout: any;
  le: LayoutElement;
  value;
  kit: any;
}

const getMediaStyle = (le, layout) =>
  _.get(le.overrides, ['style', 'media']) || layout.style;
const getMediaItem = (value): any => value[0];
const getClassNames = (le, layout): string => {
  const classNamesConfig = {
    'media-layout-preview': true,
  };

  const mediaStyle = getMediaStyle(le, layout);
  if (mediaStyle) {
    classNamesConfig[`media-layout-preview-${mediaStyle}`] = true;
  }

  return classNames(classNamesConfig);
};

const getImageShadow = le => {
  return le.getLayoutSelectionStore().getImageShadow(le.containingSegment, le);
};

const shouldShowShadow = (le): boolean => {
  const imageShadow = getImageShadow(le);
  return (
    imageShadow &&
    (imageShadow.left || imageShadow.top) &&
    !le.imageFitFullMode()
  );
};

const getImageCrop = (le, mediaItem) => {
  const imageLayout = le && le.getLayoutBySubType(ElementSubTypes.Image);
  const crop =
    le.containingSegment &&
    le.containingSegment.node &&
    imageLayout &&
    le.containingSegment.node.getCropOfImage(
      imageLayout.argIndex,
      mediaItem.relativeUri,
      imageLayout,
    );
  return crop;
};

const getOverride = (le, layout) =>
  le.overrides && le.overrides.media && le.overrides.media[layout.argIndex];

const getImageFit = (le, layout) => {
  const override = getOverride(le, layout);
  return _.get(override, ['properties', 'displayMode']) === 'full';
};

const getImageStyle = (boundary, le, layout, value) => {
  const fit = getImageFit(le, layout);
  const backgroundImageUrl = getBackgroundImageUrl(boundary, le, value, fit);

  return {
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    height: boundary.height,
    width: boundary.width,
    backgroundImage: `url(${backgroundImageUrl})`,
    backgroundSize: fit ? 'contain' : 'cover',
  };
};

const getBackgroundImageUrl = (
  boundary,
  le,
  value,
  fit,
): string | undefined => {
  const mediaItem = getMediaItem(value);

  const options: any = {
    crop: getImageCrop(le, mediaItem),
    fit,
  };

  return mediaGallery.itemToThumbnailUrl(
    mediaItem,
    boundary.width,
    boundary.height,
    options,
  );
};

export const Image: React.FunctionComponent<ImageProps> = ({
  boundary,
  value,
  layout,
  le,
  kit,
}) => {
  const style = getImageStyle(boundary, le, layout, value);

  if (shouldShowShadow(le)) {
    return (
      <ImageShadow boundary={boundary} kit={kit} layout={layout} le={le}>
        <div style={style} className={getClassNames(le, layout)} />
      </ImageShadow>
    );
  } else {
    return <div style={style} className={getClassNames(le, layout)} />;
  }
};
