import * as React from 'react';
import { mediaGallery } from '@wix/adi-core-santa-layer';

const getStyle = (width, height, left = 0, mediaItem): React.CSSProperties => {
  const style: React.CSSProperties = {
    height,
    width,
    msTransform: `scale(${mediaGallery.getScaleToFill(
      mediaItem,
      width,
      height,
    )})`,
    msTransformOrigin: 'center center',
  };

  style.left = left;

  return style;
};

interface VideoBgProps {
  mediaItem: any;
  width: number;
  height: number;
  left?: number;
}

export const VideoBg: React.FunctionComponent<VideoBgProps> = ({
  mediaItem,
  width,
  height,
  left,
}) => (
  <video
    className="media-bg video-bg"
    muted
    style={getStyle(width, height, left, mediaItem)}
  >
    <source type="video/mp4" src={mediaGallery.getVideoUrl(mediaItem)} />
  </video>
);
