import * as React from 'react';
import * as _ from 'lodash';
import {
  themeChooser,
  LayoutElement,
  mobileFonts,
  htmlEncode,
  modelStyles,
  previewColors,
  layoutSelectionStoreProvider,
  applicationParameters,
  Flags,
} from '@wix/adi-core-santa-layer';

import {
  convertToNumber,
  getCatalogStyleDefinition,
} from '../../../../utils/utils';

interface Iboundary {
  height: number;
  width: number;
  top: number;
  left: number;
}

export interface ButtonProps {
  layout: any;
  le: LayoutElement;
  value;
  kit: any;
  styleId: string;
  boundary: Iboundary;
}

const noBg = (styleProp, _property = 'alpha-bg') =>
  !styleProp[_property] || styleProp[_property] === '0';
const noBorder = styleProp => !styleProp.brd || styleProp.brd === '0';

const getJustifyContent = (layout): string => {
  let justifyContent = 'center';

  switch (layout.align) {
    case 'left':
      justifyContent = 'flex-start';
      break;
    case 'right':
      justifyContent = 'flex-end';
      break;
    default:
      justifyContent = 'center';
  }
  return justifyContent;
};

const getMargin = (layout: any): string | 0 => layout.margin || 0;

const getBorderWidth = (styleDef: any): number | undefined => {
  const value = convertToNumber(styleDef.properties.brw);
  if (styleDef.properties['alpha-brd'] === '1' && value > 0) {
    return value;
  } else {
    return;
  }
};

const getBorderRadius = (styleDef: any): number | undefined => {
  const value = convertToNumber(styleDef.properties.rd);
  if (value > 0) {
    return value;
  } else {
    return;
  }
};

const getBoxShadow = (styleDef: any): string | undefined => {
  if (
    styleDef.properties['boxShadowToggleOn-shd'] === 'true' &&
    styleDef.properties.shd
  ) {
    return styleDef.properties.shd;
  } else {
    return;
  }
};

const getColors = (le, styleId, layout, kit) => {
  const colors = {
    borderColor: undefined,
    backgroundColor: undefined,
  };

  if (Flags.designKit) {
    const buttonStyleDef = modelStyles.getButtonColoredStyleDefinition(
      le,
      styleId,
      layout.subType || layout.type,
    );
    const overrides = getButtonColorOverride(le, layout, styleId);

    const bgColor =
      overrides.bg || _.get(buttonStyleDef, ['style', 'properties', 'bg']);
    const brdColor =
      overrides.brd || _.get(buttonStyleDef, ['style', 'properties', 'brd']);

    if (!noBorder(buttonStyleDef.style.properties) && brdColor) {
      colors.borderColor = previewColors.getRGBColor(brdColor, { kit });
      // $clone[0].style.setProperty('border-color', brdColor, 'important');
    }

    if (!noBg(buttonStyleDef.style.properties) && bgColor) {
      colors.backgroundColor = previewColors.getRGBColor(bgColor, { kit });
      // $clone[0].style.setProperty('background-color', bgColor, 'important');
    }
  }
  return colors;
};

const getLss = le => {
  const node = le.containingSegment.getRealNodeFromPreviewNode();
  return layoutSelectionStoreProvider.getLayoutSelectionStore(
    node.containerPageId,
  );
};

const getButtonColorOverride = (le, layout, styleId) => {
  const lss = applicationParameters.isInMobileEditMode() && getLss(le);
  return getButtonColoredStyleDefinitionProperties(lss, layout, le, styleId);
};

const getButtonColoredStyleDefinitionProperties = (
  lss,
  layout,
  le,
  styleId,
) => {
  const styleKey = layout.subType || layout.type;
  const styleDef = modelStyles.getButtonColoredStyleDefinition(
    le,
    styleId,
    styleKey,
    lss,
  );
  return _.get(styleDef, ['style', 'properties']) || {};
};

const getTextColor = (
  le: LayoutElement,
  styleId: string,
  layout: any,
  kit: any,
) => {
  let textColor;
  if (Flags.designKit) {
    const buttonStyleDef = modelStyles.getButtonColoredStyleDefinition(
      le,
      styleId,
      layout.subType || layout.type,
    );
    const overrides = getButtonColorOverride(le, layout, styleId);
    const color =
      overrides.txt || _.get(buttonStyleDef, ['style', 'properties', 'txt']);

    if (color) {
      textColor = previewColors.getRGBColor(color, { kit });
    }
  }
  return textColor;
};

const getContainerStyle = (
  layout,
  styleDef,
  boundary,
  le,
  kit,
  styleId,
): React.CSSProperties => {
  const margin = getMargin(layout);

  const style: React.CSSProperties = {
    ...boundary,
    position: 'absolute' as 'absolute',
    display: 'flex',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    justifyContent: getJustifyContent(layout),
    paddingLeft: margin,
    paddingRight: margin,
  };

  const borderWidth = getBorderWidth(styleDef);
  if (borderWidth) {
    style.borderWidth = borderWidth;
  }

  const boxShadow = getBoxShadow(styleDef);
  if (boxShadow) {
    style.boxShadow = boxShadow;
  }

  const borderRadius = getBorderRadius(styleDef);
  if (borderRadius) {
    style.borderRadius = borderRadius;
  }

  const { backgroundColor, borderColor } = getColors(le, styleId, layout, kit);
  if (backgroundColor) {
    style.backgroundColor = backgroundColor;
  }
  if (borderColor) {
    style.borderColor = borderColor;
  }

  if (borderColor || borderWidth || borderRadius) {
    style.borderStyle = 'solid';
  }

  return style;
};

const getFont = (fontKey: string): string => {
  return themeChooser.getFontStyle(fontKey).replace(/\+/g, ' ');
};

const getFontSize = (layout: any, fontKey: string): string => {
  return layout.isMobile
    ? mobileFonts.getFontSize(fontKey)
    : themeChooser.getFontSize(fontKey);
};

const getLineHeight = (fontKey: any): string =>
  themeChooser.getLineHeight(fontKey);

const getSpanStyle = (layout, le, styleId, kit): React.CSSProperties => {
  const fontKey = 'font_' + layout.font;
  const style: React.CSSProperties = {
    width: 'auto',
    height: 'auto',
    marginLeft: 0,
    marginRight: 0,
    font: getFont(fontKey),
    fontSize: getFontSize(layout, fontKey),
    lineHeight: getLineHeight(fontKey),
  };

  const color = getTextColor(le, styleId, layout, kit);
  if (color) {
    style.color = color;
    style.WebkitTextFillColor = color;
  }

  return style;
};

//COLORS FOR backgroundColor, borderColor where set as important, might need to use REF as hack

export const Button: React.FunctionComponent<ButtonProps> = ({
  layout,
  le,
  value,
  kit,
  styleId,
  boundary,
}) => {
  const styleDef = getCatalogStyleDefinition(styleId);

  return (
    <div
      style={getContainerStyle(layout, styleDef, boundary, le, kit, styleId)}
    >
      <span style={getSpanStyle(layout, le, styleId, kit)}>
        {htmlEncode(value)}
      </span>
    </div>
  );
};
