import * as React from 'react';
import { DEFAULT_URL_HTML_CODE } from '@wix/adi-core-santa-layer';
// import * as s from './Html.scss';

interface Iboundary {
  height: number;
  width: number;
}

interface HtmlProps {
  boundary: Iboundary;
}

const getContainerStyle = (boundary: Iboundary): React.CSSProperties => {
  return {
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    height: boundary.height,
    width: boundary.width,
    overflow: 'hidden',
    position: 'relative',
  };
};

export const Html: React.FunctionComponent<HtmlProps> = ({ boundary }) => {
  return (
    <div
      className="media-layout-preview"
      style={getContainerStyle(boundary)}
      dangerouslySetInnerHTML={{ __html: DEFAULT_URL_HTML_CODE }}
    />
  );
};
