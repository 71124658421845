import {
  richTextService,
  previewColors,
  applicationParameters,
  layoutSelectionStoreProvider,
  LayoutElement,
} from '@wix/adi-core-santa-layer';

export const applyTextStyle = (
  clone: HTMLElement,
  innerChild: HTMLElement,
  le: LayoutElement,
  layout: any,
  kit: any,
  value: string,
) => {
  const align = le.getAlignment(layout, 'align');
  const formatting = getFormatting(true, layout, le);
  let bgColor = formatting && formatting.getBGColor();
  if (bgColor) {
    bgColor = previewColors.getRGBColor(bgColor, { kit });
  }
  if (bgColor) {
    innerChild.style.setProperty('background-color', bgColor, 'important');
  }
  const color = previewColors.getRGBColor(getTextColor(layout, le), { kit });

  clone.style.setProperty('color', color);
  clone.style.setProperty('-webkit-text-fill-color', color);

  clone.style.setProperty('width', 'auto');
  clone.style.setProperty('max-width', 'none');
  clone.style.setProperty('text-align', align);

  clone.style.setProperty('display', 'block');
  clone.style.setProperty('white-space', 'normal');
  clone.style.setProperty('word-wrap', 'break-word');

  clone.className += ` text-align-${align}`;

  const richText = richTextService.isRichText(value)
    ? richTextService.unMarkRichText(value)
    : richTextService.transformForEditor(value, formatting);
  innerChild.innerHTML = richText;
};

const getFormatting = (withOverrides = true, layout, le) => {
  const lss =
    applicationParameters.isInMobileEditMode() && getLss(le.containingSegment);
  return le.getFormatting(layout, null, lss, withOverrides);
};

const getLss = seg => {
  const node = seg.getRealNodeFromPreviewNode();
  return layoutSelectionStoreProvider.getLayoutSelectionStore(
    node.containerPageId,
  );
};

const getTextColor = (layout, le) =>
  getTextColorFromFormatting(true, layout, le);

const getTextColorFromFormatting = (withOverrides, layout, le) => {
  const formatting = getFormatting(withOverrides, layout, le);
  return (formatting && `color_${formatting.getColor()}`) || 'color_15';
};

export const emptyElement = element => {
  while (element.firstChild) {
    element.removeChild(element.firstChild);
  }
};
