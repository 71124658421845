import * as React from 'react';
import * as _ from 'lodash';
import {
  previewColors,
  designKit,
  LayoutElement,
} from '@wix/adi-core-santa-layer';

interface Iboundary {
  height: number;
  width: number;
}

interface SubscribeFooterProps {
  boundary: Iboundary;
  le: LayoutElement;
  kit: any;
  layout: any;
}

const getContainerStyle = (boundary: Iboundary): React.CSSProperties => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  alignContent: 'space-around',
  justifyContent: 'center',
  height: boundary.height,
  width: boundary.width,
});

const getTitleStyle = (invertColor: string): React.CSSProperties => ({
  alignSelf: 'center',
  marginBottom: '30px',
  fontSize: '14px',
  fontFamily: '"Avenir - Medium", sans-serif',
  color: invertColor,
});

const getEmailStyle = (invertColor: string): React.CSSProperties => ({
  width: '100%',
  marginBottom: '5px',
  fontSize: '14px',
  fontFamily: '"Avenir - Medium", sans-serif',
  padding: '2px',
  color: invertColor,
  border: `1px solid ${invertColor}`,
});

const getButtonStyle = (invertColor: string): React.CSSProperties => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  alignItems: 'center',
  marginBottom: '5px',
  background: invertColor,
});

const getButtonTextStyle = (
  invertColor: string,
  mainColor: string,
): React.CSSProperties => ({
  fontSize: '14px',
  fontFamily: '"Avenir - Medium", sans-serif',
  padding: '2px',
  background: invertColor,
  color: mainColor,
});

export const SubscribeFooter: React.FunctionComponent<SubscribeFooterProps> = ({
  boundary,
  le,
  kit,
  layout,
}) => {
  const invertColor: string = previewColors.getRGBColor(
    designKit.getColorByField('form', le.containingSegment, le),
    { kit },
  );
  const mainColor: string = previewColors.getRGBColor(
    designKit.getColorOfFirstBackground(le, layout),
    { kit },
  );

  return (
    <div style={getContainerStyle(boundary)}>
      <div
        style={getTitleStyle(invertColor)}
        className="subscribe-in-footer-preview-title"
      >
        Subscribe
      </div>
      <div
        style={getEmailStyle(invertColor)}
        className="subscribe-in-footer-preview-email"
      >
        Email Address
      </div>
      <div
        style={getButtonStyle(invertColor)}
        className="subscribe-in-footer-preview-button"
      >
        <div
          style={getButtonTextStyle(invertColor, mainColor)}
          className="subscribe-in-footer-preview-button-text"
        >
          Sign Up
        </div>
      </div>
    </div>
  );
};
