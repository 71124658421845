import * as ReactDOM from 'react-dom';
import * as React from 'react';
import {
  SiteLocation,
  presetPreviewResults,
} from '../coreFacade/presetPreviewResults';
import { caas } from '../caas/caas';
import {
  adiPaas,
  IPresetPreview,
  PageAlternative,
  ContentItems,
  HeaderFooterContentItem,
  PaaSRenderedModel,
} from '@wix/adi-core-santa-layer';
import { ElementSubType } from '@wix/adi-core-types';
import { PresetPreviewList } from '../sandbox/components/PresetPreviewList/PresetPreviewList';

export interface paOptions {
  locale: string;
  renderElement?: HTMLElement;
  industryId?: string;
  structureId?: string;
}

const DEFAULT_PRESET_API_OPTIONS: Partial<paOptions> = {
  locale: 'en',
  industryId: '60d9d7f53c8409f32a4130ec',
  structureId: '5603bb67000870d80c53d49d',
};
export class PresetApi {
  options: paOptions;
  documentServices?: any;
  presetDomContainer?: HTMLElement;
  onSelectSection?: (isSection: boolean) => void;
  locationOnSiteCallBack?: () => SiteLocation;
  constructor(options: paOptions) {
    if (!options) {
      throw new Error('Options can not be empty!');
    }
    this.options = { ...DEFAULT_PRESET_API_OPTIONS, ...options };
    caas.initContent(
      this.options.industryId || '',
      this.options.structureId || '',
      {},
    );
  }

  private addCss() {
    const cssRules = ''; //'h3 { color:black; background:red; }';
    const styleElement = document.createElement('style');
    styleElement.appendChild(document.createTextNode(cssRules));
    document.getElementsByTagName('head')[0].appendChild(styleElement);
  }

  init(
    renderElement?: HTMLElement,
    onSelectSection?: (isSection: boolean) => void,
  ) {
    renderElement = renderElement || this.options.renderElement;
    if (renderElement) {
      this.addCss();
      this.presetDomContainer = renderElement;
      this.onSelectSection = onSelectSection;
      this.renderPresetPreviewList();
    }
    return true;
  }

  renderPresetPreviewList() {
    const presetPreviewList = React.createElement(PresetPreviewList, {
      onSelectSection: this.onSelectSection,
    });
    ReactDOM.render(presetPreviewList, this.presetDomContainer);
  }

  setdocumentServicesHandle(documentServices: any) {
    this.documentServices = documentServices;
    return adiPaas.init(this.documentServices);
  }
  setLocationOnSiteCallback(callback: () => SiteLocation) {
    this.locationOnSiteCallBack = callback;
  }

  updateContent(options: Partial<paOptions>) {
    if (options.locale && caas.lang !== options.locale) {
      caas.setLang(options.locale);
    }
    this.options = { ...this.options, ...options };
    caas.initContent(
      this.options.industryId || '',
      this.options.structureId || '',
      {},
    );
  }

  suggestSectionPresets(
    contentItems: ContentItems | HeaderFooterContentItem,
    count: number,
    withMobile: boolean = false,
  ) {
    if (!this.documentServices) {
      return;
    }
    return adiPaas.init(this.documentServices).then(() => {
      console.log('finish init');
      return adiPaas
        .suggestSectionPresets(
          contentItems,
          count,
          undefined,
          undefined,
          undefined,
          withMobile,
        )
        .then((previews: IPresetPreview[]) => {
          presetPreviewResults.setPresetPreviews(previews);
          if (this.locationOnSiteCallBack) {
            const location: SiteLocation = this.locationOnSiteCallBack();
            presetPreviewResults.setLocationOnPage(location);
          }
          this.renderPresetPreviewList();
          return previews;
        });
    });
  }

  addPreset = (
    presetPreview: IPresetPreview,
    { page, top }: SiteLocation,
  ): Promise<PaaSRenderedModel> => {
    if (page) {
      return adiPaas.selectSection(presetPreview, page, top);
    }
  };

  addPagePreset = (
    pagePresetPreview: PageAlternative,
    pageName: string = 'welcome',
    shouldNavigateToPage: boolean = true,
  ) => {
    return adiPaas.selectPagePreset(
      pagePresetPreview,
      pageName,
      shouldNavigateToPage,
    );
  };

  getSectionPresets(
    contentItems: ContentItems | HeaderFooterContentItem,
    count: number,
    withMobile: boolean = false,
  ) {
    return adiPaas
      .suggestSectionPresets(
        contentItems,
        count,
        undefined,
        undefined,
        undefined,
        withMobile,
      )
      .then((previews: IPresetPreview[]) => {
        presetPreviewResults.setPresetPreviews(previews);
        return previews;
      });
  }

  onPresetPreviewBeforeRender(preview: IPresetPreview) {
    adiPaas.setConfigurationForSection(preview);
  }

  suggestPagePresets(
    contentItemList: ContentItems[],
    countOrCuratedTemplateIds: number | number[],
    withMobile: boolean = false,
  ) {
    if (!this.documentServices) {
      return;
    }
    return adiPaas.init(this.documentServices).then(() => {
      console.log('finish init');
      return adiPaas
        .suggestPagePresets(
          contentItemList,
          countOrCuratedTemplateIds,
          withMobile,
        )
        .then((pagePreviews: PageAlternative[]) => {
          presetPreviewResults.setPagePreviews(pagePreviews);
          this.renderPresetPreviewList();
          return pagePreviews;
        });
    });
  }

  getPagePresets(
    contentItemList: ContentItems[],
    countOrCuratedTemplateIds: number | number[],
    withMobile: boolean = false,
  ) {
    return adiPaas
      .suggestPagePresets(
        contentItemList,
        countOrCuratedTemplateIds,
        withMobile,
      )
      .then((pagePreviews: PageAlternative[]) => {
        presetPreviewResults.setPagePreviews(pagePreviews);
        return pagePreviews;
      });
  }

  overrideContentItemValues(
    contentItems: ContentItems,
    overrideValues: { [key in ElementSubType]: string },
  ): ContentItems {
    return {
      ...contentItems,
      contentItems: contentItems.contentItems.map(contentItem => ({
        ...contentItem,
        ...overrideValues,
      })),
    };
  }
}
