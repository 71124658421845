import * as React from 'react';
import * as _ from 'lodash';
import {
  designKit,
  previewColors,
  LayoutElement,
  Flags,
} from '@wix/adi-core-santa-layer';
import { TinyMenu } from './MenuComponents/TinyMenu/TinyMenu';
import { NormalMenu } from './MenuComponents/NormalMenu/NormalMenu';

interface Iboundary {
  height: number;
  width: number;
}

interface MenuProps {
  layout: any;
  le: LayoutElement;
  kit: any;
  boundary: Iboundary;
}

const getColor = (le: LayoutElement, kit: any, layout: any): string => {
  let color;
  if (Flags.designKit) {
    const origLayout = le.getLayoutBySubType(layout.subType);
    const menuBgColor = le.isLayoutInMenuStripArea(origLayout)
      ? le.containingLG.getLgData().menuStrip.color
      : '{' + designKit.getHeaderBg([le.containingLG]) + '}';
    if (menuBgColor) {
      color = previewColors.getRGBColor(
        designKit.invertToBrightestColor(menuBgColor),
        {
          kit,
        },
      );
    }
  }
  return color;
};

const isTinyMenu = (boundary: Iboundary): boolean =>
  boundary.height === boundary.width;

export const Menu: React.FunctionComponent<MenuProps> = ({
  layout,
  le,
  kit,
  boundary,
}) => {
  const color = getColor(le, kit, layout);

  return isTinyMenu(boundary) ? (
    <TinyMenu color={color} boundary={boundary} />
  ) : (
    <NormalMenu color={color} boundary={boundary} le={le} />
  );
};
