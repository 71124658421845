import * as _ from 'lodash';
import {
  applicationParameters,
  LayoutElement,
  previewColors,
  designKit,
  modelTree,
  TreeNode,
  mediaGallery,
  isLightboxType,
  designKitParameters,
  Flags,
} from '@wix/adi-core-santa-layer';
import { ElementTypes } from '@wix/adi-core-types';
import { MOBILE_WIDTH, WIDTH } from './SinglePresetPreview';

const getImageStretch = le =>
  le.variationsMap &&
  le.variationsMap.media &&
  le.variationsMap.media.imageStretch;

export const getLayoutBoundary = (layout, le: LayoutElement, columnsWidth?) => {
  let { width, left, top } = layout;
  let height = layout.rendererHeight || layout.height;

  if (le.isMediaType(layout)) {
    const override =
      le.overrides && le.overrides.media && le.overrides.media[layout.argIndex];
    if (!override || override.name === 'Image') {
      const imageStretch = getImageStretch(le);
      if (imageStretch) {
        top -= imageStretch.top;
        left -= imageStretch.left;
        width += imageStretch.left + imageStretch.right;
        height += imageStretch.top + imageStretch.bottom;
      }
    }
  } else if (layout.type === ElementTypes.Line) {
    const lineData = le.getLineWidthAndLeftPosition(layout);
    width = lineData.width;
    if (layout.docked && layout.docked.left && layout.docked.right) {
      const previewWidth = applicationParameters.isInMobileEditMode()
        ? MOBILE_WIDTH
        : WIDTH;
      width = previewWidth - layout.docked.left.px - layout.docked.right.px;
      left = columnsWidth
        ? (columnsWidth - width) / 2
        : (layout.width - previewWidth) / 2;
    } else {
      left = lineData.left;
    }
  }

  return {
    width,
    height,
    top,
    left,
  };
};

export const getPageBgColor = (solution, node) => {
  let pageBgColor;
  const isLightbox = isLightboxType(node.contentElementType);

  if (isLightbox) {
    pageBgColor = previewColors.getRGBColor(designKitParameters.D1, {
      kit: this.kit,
    });
  } else if (node.containerPageId === 'footer') {
    // footer
    pageBgColor = previewColors.getRGBColor(
      (Flags.designKit && designKit.getFooterBg(solution)) || 1,
      {
        kit: this.kit,
      },
    );
  } else if (node.contentElementType === 'GeneralDescription') {
    // header
    pageBgColor = previewColors.getRGBColor(
      (Flags.designKit && designKit.getHeaderBg(solution)) || 1,
      {
        kit: this.kit,
      },
    );
  } else {
    // body
    const pageNode = modelTree.getPageNodeById(node.containerPageId);
    pageBgColor = previewColors.getRGBColor(
      (pageNode && pageNode.bgObj && pageNode.bgObj.color) || 1,
      {
        kit: this.kit,
      },
    );
  }
  return pageBgColor;
};

export const getHookByNode = (node: TreeNode): string => {
  if (node.containerPageId === 'footer') {
    return 'footer';
  } else if (node.contentElementType === 'GeneralDescription') {
    return 'header';
  } else {
    return 'body';
  }
};

export const calculateStripHeight = (
  solution,
  lgDataArray,
  startIndex,
  stripSize,
) => {
  return _.sumBy(_.range(stripSize), i => {
    const lgData = lgDataArray[startIndex + i] || lgDataArray[0];
    const strip = lgData.strip || {};

    const lgHeight = solution[startIndex + i].getRenderedHeight();

    const stripPaddingTop = strip.paddingTop || 0;
    const stripPaddingBottom = strip.paddingBottom || 0;
    const inlinePaddingTop = strip.inlinePaddingTop || 0;
    const inlinePaddingBottom = strip.inlinePaddingBottom || 0;
    const height =
      lgHeight +
      stripPaddingTop +
      stripPaddingBottom +
      inlinePaddingTop +
      inlinePaddingBottom;
    return Math.round(height);
  });
};

export const getPartialColumnContentOffset = (
  strip,
  elementWidth: number,
  lg,
): number => {
  const partialContentPosition = strip.partialContentPosition;
  const contentColumnWidth = (strip.partialWidth / 12) * elementWidth;
  const contentWidth = applicationParameters.getPixelsFromGridUnits(
    strip.partialWidth,
  );

  let offset = (contentColumnWidth - contentWidth - 10) / 2;
  if (partialContentPosition === 'left') {
    offset = -offset;
  }
  return offset;
};

export const stripBackgroundThumbnail = (mediaRef, width, height) => {
  const imgRef = mediaRef.posterImageRef || mediaRef;
  return mediaGallery.itemToThumbnailUrl(
    {
      mediaType: imgRef.type.toLowerCase(),
      relativeUri: imgRef.uri,
    },
    width,
    height,
  );
};

export const getAllParentsOfNode = nodeToFindParentsOf => {
  let currentParent = nodeToFindParentsOf.parentNode;
  const parents = [];
  let safetyCount = 1000;
  while (currentParent) {
    parents.push(currentParent);
    currentParent = currentParent.parentNode;
    if (--safetyCount === 0) {
      throw new Error('Something went wrong. Found 1000+ parents!');
    }
  }

  return parents;
};

export const getParentWithClass = (
  nodeToFindParentsOf: HTMLElement,
  className: string,
) => {
  const allParents = getAllParentsOfNode(nodeToFindParentsOf);
  return allParents.filter(
    (parent: HTMLElement) =>
      parent.classList && parent.classList.contains(className),
  );
};

export const removeAllChildNodes = (node: HTMLElement): void => {
  let child = node.lastElementChild;
  while (child) {
    node.removeChild(child);
    child = node.lastElementChild;
  }
};

export const getSiblings = (elem: HTMLElement): HTMLElement[] => {
  const siblings = [];
  let sibling = elem.parentNode.firstChild;

  while (sibling) {
    if (sibling.nodeType === 1 && sibling !== elem) {
      siblings.push(sibling);
    }
    sibling = sibling.nextSibling;
  }

  return siblings;
};

export const removePx = (text: string): number => parseInt(text, 10);

export const getParentsUntil = (elem, parent, selector: string) => {
  const parents = [];
  let parentType;
  if (parent) {
    parentType = parent.charAt(0);
  }

  let selectorType;
  if (selector) {
    selectorType = selector.charAt(0);
  }

  for (; elem && elem !== document; elem = elem.parentNode) {
    if (parent) {
      if (parentType === '.') {
        if (elem.classList.contains(parent.substr(1))) {
          break;
        }
      }

      if (parentType === '#') {
        if (elem.id === parent.substr(1)) {
          break;
        }
      }

      if (parentType === '[') {
        if (elem.hasAttribute(parent.substr(1, parent.length - 1))) {
          break;
        }
      }

      if (elem.tagName.toLowerCase() === parent) {
        break;
      }
    }

    if (selector) {
      if (selectorType === '.') {
        if (elem.classList.contains(selector.substr(1))) {
          parents.push(elem);
        }
      }

      if (selectorType === '#') {
        if (elem.id === selector.substr(1)) {
          parents.push(elem);
        }
      }

      if (selectorType === '[') {
        if (elem.hasAttribute(selector.substr(1, selector.length - 1))) {
          parents.push(elem);
        }
      }

      if (elem.tagName.toLowerCase() === selector) {
        parents.push(elem);
      }
    } else {
      parents.push(elem);
    }
  }
  if (parents.length === 0) {
    return null;
  } else {
    return parents;
  }
};

export const wrapAllWithElement = (
  elementsToWrap: HTMLElement[] | Element[],
  wrappingElementString: string,
) => {
  const wrappingElementContainer = document.createElement('div');
  wrappingElementContainer.innerHTML = wrappingElementString;
  const wrappingElement = wrappingElementContainer.children[0];

  elementsToWrap.forEach(elementToWrap => {
    const clonedWrapper = wrappingElement.cloneNode(true);
    elementToWrap.parentNode.insertBefore(clonedWrapper, elementToWrap);
    clonedWrapper.appendChild(elementToWrap);
  });
};
