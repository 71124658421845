import * as React from 'react';
import {
  LayoutElement,
  previewColors,
  designKit,
} from '@wix/adi-core-santa-layer';
// import * as s from './ImageShadow.scss';

interface Iboundary {
  height: number;
  width: number;
}

interface ImageShadowProps {
  boundary: Iboundary;
  layout: any;
  kit: any;
  le: LayoutElement;
}

export const ImageShadow: React.FunctionComponent<ImageShadowProps> = ({
  boundary,
  layout,
  kit,
  children,
  le,
}) => {
  const imageShadow = le
    .getLayoutSelectionStore()
    .getImageShadow(le.containingSegment, le);
  const color = previewColors.getRGBColor(
    designKit.getLayoutColorContrastToBackground(le, layout),
    { kit },
  );

  return (
    <div
      className="media-layout-shadow-preview"
      style={{ position: 'relative' }}
    >
      <div
        style={{
          position: 'absolute',
          height: boundary.height,
          width: boundary.width,
          left: imageShadow.left || 0,
          top: imageShadow.top || 0,
          background: `${color}`,
        }}
      />
      <div style={{ position: 'relative' }}>{children}</div>
    </div>
  );
};
