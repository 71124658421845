import * as React from 'react';
import { LayoutElement } from '@wix/adi-core-santa-layer';
// import * as s from './NormalMenu.scss';

interface Iboundary {
  height: number;
  width: number;
}

interface NormalMenuProps {
  le: LayoutElement;
  boundary: Iboundary;
  color: string;
}

const getMenuStyle = (
  boundary: Iboundary,
  menuAlignment,
  color,
): React.CSSProperties => {
  const style: React.CSSProperties = {
    display: 'flex',
    flexDirection: 'row',
    height: boundary.height,
    width: boundary.width,
    justifyContent:
      menuAlignment === 'left'
        ? 'flex-start'
        : menuAlignment === 'right'
        ? 'flex-end'
        : 'center',
  };

  if (color) {
    style.color = color;
  }

  return style;
};

const getMenuItemStyle = (menuAlignment, lss): React.CSSProperties => {
  const menuStretchButtons = getButtonStretch(lss);

  const style: React.CSSProperties = {
    padding: '4px 10px',
    fontSize: '14px',
    textAlign: menuAlignment,
    flex: menuStretchButtons ? '1 0 0' : '0 0 auto',
  };

  return style;
};

const getMenuAlignment = (lss): string =>
  lss ? lss.menuOverridesManager.alignment() : 'center';

const getButtonStretch = (lss): boolean =>
  lss ? lss.menuOverridesManager.stretchButtons() : true;

const getMenuItem = (text: string, style: React.CSSProperties, key: number) => (
  <div className="menu-layout-preview" style={style} key={key}>
    {text}
  </div>
);

export const NormalMenu: React.FunctionComponent<NormalMenuProps> = ({
  le,
  boundary,
  color,
}) => {
  const lss = le.getLayoutSelectionStore();
  const menuAlignment = getMenuAlignment(lss);
  const menuItemStyle = getMenuItemStyle(menuAlignment, lss);

  return (
    <div style={getMenuStyle(boundary, menuAlignment, color)}>
      {['Home', 'About', 'Services', 'Contact'].map((text, i) =>
        getMenuItem(text, menuItemStyle, i),
      )}
    </div>
  );
};
