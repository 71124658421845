import {
  buttonStyleResolver,
  layoutElementButtons,
} from '@wix/adi-core-santa-layer';
import { ButtonProps } from '../Button/Button';

const getButtonBoundary = dims => {
  return {
    left: dims.offset.x,
    top: dims.offset.y,
    width: dims.width,
    height: dims.height,
  };
};

const getButtonDimensions = (le, index) => le.buttonDimensions[index];

const applyEllipsisIfNeeded = (le, bArgValue, layout, dims, value): string => {
  let callToAction = le.getLayoutValue(layout, bArgValue);
  const textContainerWidth = dims.width - 2 * layout.margin;
  const textWidth = dims.textDimensions.width;
  if (textWidth > textContainerWidth) {
    const newLength = Math.floor(
      textContainerWidth * (value.length / textWidth) * 0.95,
    );
    callToAction = callToAction.substr(0, newLength) + '...';
  }
  return callToAction;
};

export const getStyleId = (layout, mainButton = true) => {
  let styleId = layout.style;

  if (!mainButton) {
    let catalogId = buttonStyleResolver.toCatalogId(layout.style);
    catalogId = catalogId && buttonStyleResolver.getExtraButtonStyle(catalogId);
    styleId = catalogId
      ? buttonStyleResolver.toStyleId(catalogId)
      : layout.style;
  }
  return styleId;
};

export const getButtonBoundaryAndApplyEllipsisIfNeeded = (
  bArgValue,
  index,
  le,
  layout,
  value,
) => {
  const dims = getButtonDimensions(le, index);

  if (!dims) {
    return;
  }

  const boundary = getButtonBoundary(dims);
  const buttonValue = applyEllipsisIfNeeded(le, bArgValue, layout, dims, value);

  return { boundary, buttonValue };
};

const getButtonData = (bArgValue, index, le, layout, value, kit) => {
  const styleId = getStyleId(layout, index === 0);
  const buttonData = getButtonBoundaryAndApplyEllipsisIfNeeded(
    bArgValue,
    index,
    le,
    layout,
    value,
  );

  if (!buttonData || !buttonData.boundary || !buttonData.buttonValue) {
    return;
  }
  return {
    boundary: buttonData.boundary,
    le,
    kit,
    styleId,
    value: buttonData.buttonValue,
    layout,
  };
};

export const getButtonsData = (
  argValue,
  layout,
  le,
  value,
  kit,
): ButtonProps[] => {
  const buttonsArgValues = [
    argValue || {},
    ...layoutElementButtons.getExtraButtons(argValue),
  ];

  return buttonsArgValues
    .map((bArgValue, index) =>
      getButtonData(bArgValue, index, le, layout, value, kit),
    )
    .filter(o => !!o);
};
