import * as React from 'react';
import ReactHtmlParser from 'react-html-parser';
import {
  formSvg_460px,
  formSvg_780px,
  formSvg_940px,
  defaultForm,
} from './Forms/Forms';
import {
  previewColors,
  designKit,
  LayoutElement,
} from '@wix/adi-core-santa-layer';
// import * as s from './ContactForm.scss';

const getForm = (width: number, formColor: string, bgColor: string) => {
  let html;
  if (width === 460) {
    html = (
      <div
        dangerouslySetInnerHTML={{ __html: formSvg_460px(formColor, bgColor) }}
      />
    );
  } else if (width === 780) {
    html = (
      <div
        dangerouslySetInnerHTML={{ __html: formSvg_780px(formColor, bgColor) }}
      />
    );
  } else if (width === 940) {
    html = (
      <div
        dangerouslySetInnerHTML={{ __html: formSvg_940px(formColor, bgColor) }}
      />
    );
  }
  if (!html) {
    html = ReactHtmlParser(defaultForm(formColor, bgColor));
  }

  return html;
};

interface Iboundary {
  height: number;
  width: number;
}

const getStyle = ({ width, height }: Iboundary) => ({
  height,
  width,
});

interface ContactFormProps {
  boundary: Iboundary;
  le: LayoutElement;
  kit: any;
  layout: any;
}

export const ContactForm: React.FunctionComponent<ContactFormProps> = ({
  boundary,
  le,
  kit,
  layout,
}) => {
  const formColor: string = previewColors.getRGBColor(
    designKit.getColorByField('form', le.containingSegment, le),
    { kit },
  );
  const bgColor: string = previewColors.getRGBColor(
    designKit.getColorOfFirstBackground(le, layout),
    { kit },
  );

  return (
    <div style={getStyle(boundary)}>
      {getForm(boundary.width, formColor, bgColor)}
    </div>
  );
};
