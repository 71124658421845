import * as React from 'react';
import * as _ from 'lodash';
import classNames from 'classnames';
import {
  mediaGallery,
  MobileComponentOrganizer,
} from '@wix/adi-core-santa-layer';
import { MediaItemTypes } from '@wix/adi-core-types';

interface GridProps {
  boundary: any;
  value: any;
  override: any;
  le: any;
}

const getGridElementStyle = (
  value: any[],
  i: number,
  width: number,
  height: number,
): React.CSSProperties => {
  const style: React.CSSProperties = {};

  if (value[i] && value[i].mediaType === MediaItemTypes.Image) {
    style.flexGrow = 0;
    style.flexShrink = 0;
    style.flexBasis = 'auto';
    style.width = width;
    style.height = height;
    style.position = 'relative';
    style.backgroundPosition = 'center';
    style.backgroundRepeat = 'no-repeat';
    style.backgroundImage = `url(${mediaGallery.itemToThumbnailUrl(
      value[i],
      width,
      height,
    )})`;
  }

  return style;
};

const getProperties = (properties, boundary) => {
  const numCols = properties.numCols || 3;
  const numRows = properties.maxRows || 2;
  const margin = properties.margin || 0;
  const width = Math.floor(
    boundary.width / numCols - (numCols > 1 ? margin : 0),
  );
  const height = Math.floor(
    boundary.height / numRows - (numRows > 1 ? margin : 0),
  );

  return {
    numCols,
    numRows,
    width,
    height,
  };
};

const getGridElements = (
  properties,
  boundary,
  value,
  override,
): JSX.Element[] => {
  const { numCols, numRows, width, height } = getProperties(
    properties,
    boundary,
  );

  return _.range(numCols * numRows).map((el, i) => (
    <div key={i} style={getGridElementStyle(value, i, width, height)}>
      {isWithText(override) && getTitleElement(value[i].Title)}
    </div>
  ));
};

const getTitleElement = (title: string) => {
  const style: React.CSSProperties = {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    color: '#000000',
    background: '#ffffff',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    textAlign: 'left',
    lineHeight: '27px',
    fontSize: '24px',
    fontFamily: 'proxima-nova, sans-serif',
    fontWeight: 300,
    padding: '10px',
    height: '47px',
  };

  return (
    <div className="title" style={style}>
      {title || ' '}
    </div>
  );
};

const getContainerStyle = (boundary, properties): React.CSSProperties => {
  const style: React.CSSProperties = {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
    justifyContent: 'space-between',
    height: boundary.height,
  };

  if (!isCoast2Coast(properties)) {
    style.width = boundary.width;
  }
  return style;
};

const isCoast2Coast = (properties): boolean =>
  properties.stretch === 'coast2coast';

const isWithText = (override): boolean =>
  override.name === 'Grid (with margins and text below)';

const getSubType = override =>
  override.name === 'Animated Grid' ? 'PaginatedGridGallery' : 'MatrixGallery';

export const Grid: React.FunctionComponent<GridProps> = ({
  boundary,
  value,
  override,
  le,
}) => {
  const subType = getSubType(override);
  const properties =
    (le.isMobile() &&
      MobileComponentOrganizer.createProperties('gallery', subType)) ||
    override.properties ||
    {};

  return (
    <div
      className={classNames({
        'grid-media-layout-preview': true,
        'strip-media-layout-preview': isCoast2Coast(properties),
      })}
      style={getContainerStyle(boundary, properties)}
    >
      {getGridElements(properties, boundary, value, override)}
    </div>
  );
};
