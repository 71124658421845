import { Flags } from '@wix/adi-core-santa-layer';

export const defaultForm = (formColor: string, bgColor: string) =>
  Flags.formTitles
    ? `<div style="display: flex; flex-direction: column; height: 100%">
    <div class="form-layout-preveiw-title" style="color:${formColor};">Name</div>
    <div class="form-layout-preveiw-input" style="border-color:${formColor};color:${formColor};">Enter your name </div>
    <div class="form-layout-preveiw-title" style="color:${formColor};">Email</div>
    <div class="form-layout-preveiw-input" style="border-color:${formColor};color:${formColor};">Enter your email</div>
    <div class="form-layout-preveiw-title" style="color:${formColor};">Subject</div>
    <div class="form-layout-preveiw-input" style="border-color:${formColor};color:${formColor};">Type the subject</div>
    <div class="form-layout-preveiw-title" style="color:${formColor};">Message</div>
    <div class="form-layout-preveiw-input" style="flex: 1;border-color:${formColor};color:${formColor};">Type ypur message here...</div>
    <div style="display: flex; flex-direction: row;">
        <div style="flex: 1;"></div>
        <div class="form-layout-preveiw-button" style="border-color:${formColor};background-color:${formColor};color:${bgColor};">Submit</div>
    </div>
</div>`
    : `<div style="display: flex; flex-direction: column; height: 100%">
    <div class="form-layout-preveiw-input" style="border-color:${formColor};color:${formColor};">Name</div>
    <div class="form-layout-preveiw-input" style="border-color:${formColor};color:${formColor};">Email</div>
    <div class="form-layout-preveiw-input" style="border-color:${formColor};color:${formColor};">Subject</div>
    <div class="form-layout-preveiw-input" style="flex: 1;border-color:${formColor};color:${formColor};">Message</div>
    <div style="display: flex; flex-direction: row;">
        <div style="flex: 1;"></div>
        <div class="form-layout-preveiw-button" style="border-color:${formColor};background-color:${formColor};color:${bgColor};">Send</div>
    </div>
</div>`;

export const formSvg_460px = (formColor: string, bgColor: string) =>
  Flags.formTitles
    ? `<svg width="460px" height="483px" viewBox="0 -10 460 443" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
<g id="460" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
    <g id="Form" transform="translate(0.000000, 13.000000)">
        <path d="M133.5,427.5 L133.5,370.5 L325.5,370.5 L325.5,427.5 L133.5,427.5 Z" id="btn" stroke="${formColor}" fill="${formColor}"></path>
        <polygon id="Fill-1" stroke="${formColor}" points="0 350 460 350 460 232 0 232"></polygon>
        <polygon id="Fill-3" stroke="${formColor}" points="240 110 460 110 460 68 240 68"></polygon>
        <polygon id="Fill-4" stroke="${formColor}" points="0 189 460 189 460 148 0 148"></polygon>
        <polygon id="Fill-5" stroke="${formColor}" points="0 110 220 110 220 68 0 68"></polygon>
        <polygon id="Fill-8" stroke="${formColor}" points="240 31 460 31 460 -10 240 -10"></polygon>
        <polygon id="Fill-9" stroke="${formColor}" points="0 31 220 31 220 -10 0 -10"></polygon>
    </g>
    <g id="Text" transform="translate(20.000000, 26.000000)" font-family="Poppins-Regular, Poppins" font-weight="normal">
        <text id="Name-PlaceHolder" font-size="16" letter-spacing="0.299999982" fill="${formColor}">
            <tspan x="-10" y="3">Enter your name</tspan>
        </text>
        <text id="Name" font-size="16" letter-spacing="0.299999982" fill="${formColor}">
            <tspan x="-10" y="-32">Name</tspan>
        </text>
        <text id="Email" font-size="16" letter-spacing="0.299999982" fill="${formColor}">
            <tspan x="-10" y="47">Email</tspan>
        </text>
        <text id="Email-PlaceHolder" font-size="16" letter-spacing="0.299999982" fill="${formColor}">
            <tspan x="-10" y="82">Enter your email</tspan>
        </text>
        <text id="Subject" font-size="16" letter-spacing="0.299999982" fill="${formColor}">
            <tspan x="-10" y="125">Subject</tspan>
        </text>
        <text id="Subject-PlaceHolder" font-size="16" letter-spacing="0.299999982" fill="${formColor}">
            <tspan x="-10" y="160">Type the subject</tspan>
        </text>
        <text id="Message" font-size="16" letter-spacing="0.299999982" fill="${formColor}">
            <tspan x="-10" y="208">Message</tspan>
        </text>
        <text id="Message-PlaceHolder" font-size="16" letter-spacing="0.299999982" fill="${formColor}">
            <tspan x="-10" y="248">Type the message here…</tspan>
        </text>
        <text id="Submit" font-size="18" letter-spacing="0.337500006" fill="${bgColor}">
            <tspan x="177.8525" y="392">Submit</tspan>
        </text>
        <text id="Address-PlaceHolder" font-size="16" letter-spacing="0.299999982" fill="${formColor}">
            <tspan x="230" y="3">Enter your address</tspan>
        </text>
        <text id="Address" font-size="16" letter-spacing="0.299999982" fill="${formColor}">
            <tspan x="230" y="-32">Address</tspan>
        </text>
        <text id="Phone-Placeholder" font-size="16" letter-spacing="-0.1" fill="${formColor}">
            <tspan x="231" y="82">Enter your phone number</tspan>
        </text>
        <text id="Phone" font-size="16" letter-spacing="0.299999982" fill="${formColor}">
            <tspan x="231" y="47">Phone</tspan>
        </text>
    </g>
</g>
</svg>`
    : `<svg width="460px" height="463px" viewBox="0 0 460 463" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
<g id="460" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
    <g id="Form" transform="translate(0.000000, 13.000000)">
        <path d="M133.5,427.5 L133.5,370.5 L325.5,370.5 L325.5,427.5 L133.5,427.5 Z" id="btn" stroke="${formColor}" fill="${formColor}"></path>
        <polygon id="Fill-1" stroke="${formColor}" points="0 344 460 344 460 207 0 207"></polygon>
        <polygon id="Fill-3" stroke="${formColor}" points="240 120 460 120 460 68 240 68"></polygon>
        <polygon id="Fill-4" stroke="${formColor}" points="0 189 460 189 460 138 0 138"></polygon>
        <polygon id="Fill-5" stroke="${formColor}" points="0 120 220 120 220 68 0 68"></polygon>
        <polygon id="Fill-8" stroke="${formColor}" points="240 51 460 51 460 0 240 0"></polygon>
        <polygon id="Fill-9" stroke="${formColor}" points="0 51 220 51 220 0 0 0"></polygon>
    </g>
    <g id="Text" transform="translate(20.000000, 26.000000)" font-family="Poppins-Regular, Poppins" font-weight="normal">
        <text id="First-Name" font-size="16" letter-spacing="0.299999982" fill="${formColor}">
            <tspan x="0" y="17">First Name</tspan>
        </text>
        <text id="Email" font-size="16" letter-spacing="0.299999982" fill="${formColor}">
            <tspan x="0" y="87">Email</tspan>
        </text>
        <text id="Subject" font-size="16" letter-spacing="0.299999982" fill="${formColor}">
            <tspan x="0" y="157">Subject</tspan>
        </text>
        <text id="Message" font-size="16" letter-spacing="0.299999982" fill="${formColor}">
            <tspan x="0" y="223">Message</tspan>
        </text>
        <text id="Submit" font-size="18" letter-spacing="0.337500006" fill="${bgColor}">
            <tspan x="177.8525" y="392">Submit</tspan>
        </text>
        <text id="Last-Name" font-size="16" letter-spacing="0.299999982" fill="${formColor}">
            <tspan x="240" y="17">Last Name</tspan>
        </text>
        <text id="Address" font-size="16" letter-spacing="0.299999982" fill="${formColor}">
            <tspan x="241" y="87">Address</tspan>
        </text>
    </g>
</g>
</svg>`;

export const formSvg_780px = (formColor: string, bgColor: string) =>
  Flags.formTitles
    ? `<svg width="780px" height="483px" viewBox="0 -10 780 443" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
<g id="780" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
    <g id="Form" transform="translate(0.000000, 13.000000)">
        <path d="M293,427.5 L293,370.5 L485,370.5 L485,427.5 L293,427.5 Z" id="btn" stroke="${formColor}" fill="${formColor}"></path>
        <polygon id="Fill-1" stroke="${formColor}" points="0 350 780 350 780 232 0 232"></polygon>
        <polygon id="Fill-3" stroke="${formColor}" points="400 110 780 110 780 68 400 68"></polygon>
        <polygon id="Fill-4" stroke="${formColor}" points="0 190 780 190 780 148 0 148"></polygon>
        <polygon id="Fill-5" stroke="${formColor}" points="0 110 380 110 380 68 0 68"></polygon>
        <polygon id="Fill-8" stroke="${formColor}" points="400 32 780 32 780 -10 400 -10"></polygon>
        <polygon id="Fill-9" stroke="${formColor}" points="0 32 380 32 380 -10 0 -10"></polygon>
    </g>
    <g id="Text" transform="translate(20.000000, 26.000000)" font-family="Poppins-Regular, Poppins" font-weight="normal">
        <text id="Name-PlaceHolder" font-size="16" letter-spacing="0.299999982" fill="${formColor}">
            <tspan x="-10" y="3">Enter your name</tspan>
        </text>
        <text id="Name" font-size="16" letter-spacing="0.299999982" fill="${formColor}">
            <tspan x="-10" y="-32">Name</tspan>
        </text>
        <text id="Email" font-size="16" letter-spacing="0.299999982" fill="${formColor}">
            <tspan x="-10" y="47">Email</tspan>
        </text>
         <text id="Email-PlaceHolder" font-size="16" letter-spacing="0.299999982" fill="${formColor}">
            <tspan x="-10" y="82">Enter your email</tspan>
        </text>
        <text id="Subject" font-size="16" letter-spacing="0.299999982" fill="${formColor}">
            <tspan x="-10" y="125">Subject</tspan>
        </text>
        <text id="Subject-PlaceHolder" font-size="16" letter-spacing="0.299999982" fill="${formColor}">
            <tspan x="-10" y="160">Type the subject</tspan>
        </text>
        <text id="Message" font-size="16" letter-spacing="0.299999982" fill="${formColor}">
            <tspan x="-10" y="208">Message</tspan>
        </text>
         <text id="Message-PlaceHolder" font-size="16" letter-spacing="0.299999982" fill="${formColor}">
            <tspan x="-10" y="248">Type the message here…</tspan>
        </text>
        <text id="Submit" font-size="18" letter-spacing="0.337500006" fill="${bgColor}">
            <tspan x="337.372237" y="392">Submit</tspan>
        </text>
        <text id="Address-PlaceHolder" font-size="16" letter-spacing="0.299999982" fill="${formColor}">
            <tspan x="390" y="3">Enter your address</tspan>
        </text>
        <text id="Address" font-size="16" letter-spacing="0.299999982" fill="${formColor}">
            <tspan x="390" y="-32">Address</tspan>
        </text>
        <text id="Phone-Placeholder" font-size="16" letter-spacing="-0.1" fill="${formColor}">
            <tspan x="390" y="82">Enter your phone number</tspan>
        </text>
        <text id="Phone" font-size="16" letter-spacing="0.299999982" fill="${formColor}">
            <tspan x="390" y="47">Phone</tspan>
        </text>
    </g>
</g>
</svg>`
    : `<svg width="780px" height="470px" viewBox="0 0 780 470" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
<g id="780" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
    <g>
        <path d="M262.5,457.5 L262.5,391.5 L517.5,391.5 L517.5,457.5 L262.5,457.5 Z" id="btn" stroke="${formColor}" fill="${formColor}"></path>
        <polygon id="Fill-1" stroke="${formColor}" points="-5.68434189e-14 376 780 376 780 228 -5.68434189e-14 228"></polygon>
        <polygon id="Fill-3" stroke="${formColor}" points="401 136 780 136 780 76 401 76"></polygon>
        <polygon id="Fill-4" stroke="${formColor}" points="-5.68434189e-14 212 780 212 780 152 -5.68434189e-14 152"></polygon>
        <polygon id="Fill-5" stroke="${formColor}" points="-5.68434189e-14 136 379 136 379 76 -5.68434189e-14 76"></polygon>
        <polygon id="Fill-8" stroke="${formColor}" points="401 60 780 60 780 0 401 0"></polygon>
        <polygon id="Fill-9" stroke="${formColor}" points="-5.68434189e-14 60 379 60 379 3.55271368e-15 -5.68434189e-14 3.55271368e-15"></polygon>
    </g>
    <g id="Text" transform="translate(20.000000, 19.000000)" font-family="Poppins-Regular, Poppins" font-weight="normal">
        <text id="Submit" font-size="18" letter-spacing="0.337500006" fill="${bgColor}">
            <tspan x="337.372237" y="413">Submit</tspan>
        </text>
        <text id="First-Name" font-size="16" letter-spacing="0.300000012" fill="${formColor}">
            <tspan x="0" y="17">First Name</tspan>
        </text>
        <text id="Email" font-size="16" letter-spacing="0.300000012" fill="${formColor}">
            <tspan x="0" y="93">Email</tspan>
        </text>
        <text id="Subject" font-size="16" letter-spacing="0.300000012" fill="${formColor}">
            <tspan x="0" y="167.153061">Subject</tspan>
        </text>
        <text id="Message" font-size="16" letter-spacing="0.300000012" fill="${formColor}">
            <tspan x="0" y="237.540816">Message</tspan>
        </text>
        <text id="Last-Name" font-size="16" letter-spacing="0.300000012" fill="${formColor}">
            <tspan x="400" y="17">Last Name</tspan>
        </text>
        <text id="Address" font-size="16" letter-spacing="0.300000012" fill="${formColor}">
            <tspan x="400" y="93">Address</tspan>
        </text>
    </g>
</g>
</svg>`;

export const formSvg_940px = (formColor: string, bgColor: string) =>
  Flags.formTitles
    ? `<svg width="940px" height="483px" viewBox="0 -10 940 443" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
<g id="940" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
    <g id="Form" transform="translate(0.000000, 13.000000)">
        <path d="M371.5,427.5 L371.5,370.5 L563.5,370.5 L563.5,427.5 L371.5,427.5 Z" id="btn" stroke="${formColor}" fill="${formColor}"></path>
        <polygon id="Fill-1" stroke="${formColor}" points="0 350 940 350 940 232 0 232"></polygon>
        <polygon id="Fill-3" stroke="${formColor}" points="480 110 940 110 940 68 480 68"></polygon>
        <polygon id="Fill-4" stroke="${formColor}" points="0 190 940 190 940 148 0 148"></polygon>
        <polygon id="Fill-5" stroke="${formColor}" points="0 110 460 110 460 68 0 68"></polygon>
        <polygon id="Fill-8" stroke="${formColor}" points="480 32 940 32 940 -10 480 -10"></polygon>
        <polygon id="Fill-9" stroke="${formColor}" points="0 32 460 32 460 -10 0 -10"></polygon>
    </g>
    <g id="Text" transform="translate(20.000000, 26.000000)" font-family="Poppins-Regular, Poppins" font-weight="normal">
        <text id="Name-PlaceHolder" font-size="16" letter-spacing="0.299999982" fill="${formColor}">
            <tspan x="-10" y="3">Enter your name</tspan>
        </text>
        <text id="Name" font-size="16" letter-spacing="0.299999982" fill="${formColor}">
            <tspan x="-10" y="-32">Name</tspan>
        </text>
        <text id="Email" font-size="16" letter-spacing="0.299999982" fill="${formColor}">
            <tspan x="-10" y="47">Email</tspan>
        </text>
         <text id="Email-PlaceHolder" font-size="16" letter-spacing="0.299999982" fill="${formColor}">
            <tspan x="-10" y="82">Enter your email</tspan>
        </text>
        <text id="Subject" font-size="16" letter-spacing="0.299999982" fill="${formColor}">
            <tspan x="-10" y="125">Subject</tspan>
        </text>
        <text id="Subject-PlaceHolder" font-size="16" letter-spacing="0.299999982" fill="${formColor}">
            <tspan x="-10" y="160">Type the subject</tspan>
        </text>
        <text id="Message" font-size="16" letter-spacing="0.299999982" fill="${formColor}">
            <tspan x="-10" y="208">Message</tspan>
        </text>
         <text id="Message-PlaceHolder" font-size="16" letter-spacing="0.299999982" fill="${formColor}">
            <tspan x="-10" y="248">Type the message here…</tspan>
        </text>
        <text id="Submit" font-size="18" letter-spacing="0.337500006" fill="${bgColor}">
            <tspan x="415.8525" y="392">Submit</tspan>
        </text>
        <text id="Address-PlaceHolder" font-size="16" letter-spacing="0.299999982" fill="${formColor}">
            <tspan x="470" y="3">Enter your address</tspan>
        </text>
        <text id="Address" font-size="16" letter-spacing="0.299999982" fill="${formColor}">
            <tspan x="470" y="-32">Address</tspan>
        </text>
        <text id="Phone-Placeholder" font-size="16" letter-spacing="-0.1" fill="${formColor}">
            <tspan x="470" y="82">Enter your phone number</tspan>
        </text>
        <text id="Phone" font-size="16" letter-spacing="0.299999982" fill="${formColor}">
            <tspan x="470" y="47">Phone</tspan>
        </text>
    </g>
</g>
</svg>`
    : `<svg width="940px" height="521px" viewBox="0 0 940 521" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
<g id="940" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
    <g>
        <polygon id="btn" stroke="${formColor}" fill="${formColor}" points="324 502 617 502 617 436 324 436"></polygon>
        <polygon id="Fill-1" stroke="${formColor}" points="0 412 940 412 940 261 0 261"></polygon>
        <polygon id="Fill-3" stroke="${formColor}" points="481 152 940 152 940 87 481 87"></polygon>
        <polygon id="Fill-4" stroke="${formColor}" points="0 239 940 239 940 174 0 174"></polygon>
        <polygon id="Fill-5" stroke="${formColor}" points="0 152 459 152 459 87 0 87"></polygon>
        <polygon id="Fill-8" stroke="${formColor}" points="481 65 940 65 940 0 481 0"></polygon>
        <polygon id="Fill-9" stroke="${formColor}" points="0 65 459 65 459 0 0 0"></polygon>
    </g>
    <g id="Text" transform="translate(22.000000, 21.000000)" font-family="Poppins-Regular, Poppins" font-weight="normal">
        <text id="Submit" font-size="18" letter-spacing="0.337499976" fill="${bgColor}">
            <tspan x="415.8525" y="455">Submit</tspan>
        </text>
        <text id="First-Name" font-size="16" letter-spacing="0.299999982" fill="${formColor}">
            <tspan x="0" y="17">First Name</tspan>
        </text>
        <text id="Email" font-size="16" letter-spacing="0.299999982" fill="${formColor}">
            <tspan x="0" y="104">Email</tspan>
        </text>
        <text id="Subject" font-size="16" letter-spacing="0.299999982" fill="${formColor}">
            <tspan x="0" y="191">Subject</tspan>
        </text>
        <text id="Message" font-size="16" letter-spacing="0.299999982" fill="${formColor}">
            <tspan x="0" y="268">Message</tspan>
        </text>
        <text id="Last-Name" font-size="16" letter-spacing="0.299999982" fill="${formColor}">
            <tspan x="484" y="17">Last Name</tspan>
        </text>
        <text id="Address" font-size="16" letter-spacing="0.299999982" fill="${formColor}">
            <tspan x="484" y="104">Address</tspan>
        </text>
    </g>
</g>
</svg>`;
