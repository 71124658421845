import * as React from 'react';
// import * as s from './Map.scss';

interface Iboundary {
  height: number;
  width: number;
}

interface MapProps {
  boundary: Iboundary;
}

const getContainerStyle = (boundary: Iboundary): React.CSSProperties => {
  return {
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    height: boundary.height,
    width: boundary.width,
  };
};

export const Map: React.FunctionComponent<MapProps> = ({ boundary }) => {
  return (
    <div className="media-layout-preview" style={getContainerStyle(boundary)} />
  );
};
