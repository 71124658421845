import * as React from 'react';
import { getButtonsData } from './ButtonDataBuilder';
import { LayoutElement } from '@wix/adi-core-santa-layer';
import { Button, ButtonProps } from '../Button/Button';

interface ButtonsProps {
  argValue: any;
  layout: any;
  le: LayoutElement;
  value: any;
  kit: any;
}

export const Buttons: React.FunctionComponent<ButtonsProps> = ({
  argValue,
  layout,
  le,
  value,
  kit,
}) => {
  const buttonsData: ButtonProps[] = getButtonsData(
    argValue,
    layout,
    le,
    value,
    kit,
  );

  return (
    <div>
      {buttonsData
        .map((buttonData: ButtonProps, i: number) => (
          <Button key={i} {...buttonData} />
        ))
        .filter(o => !!o)}
    </div>
  );
};
