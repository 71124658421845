import * as React from 'react';
import * as _ from 'lodash';
import { mediaGallery } from '@wix/adi-core-santa-layer';
import { MediaItemTypes } from '@wix/adi-core-types';
import classNames from 'classnames';

interface Iboundary {
  height: number;
  width: number;
}

interface CollageProps {
  boundary: Iboundary;
  value: any;
  override: any;
}

const getBackgroundImages = (amountImages: number, value, boundary) => {
  return _.range(amountImages).map((el, i) => {
    const media = value[i] || value[value.length - 1];
    return `url(${mediaGallery.itemToThumbnailUrl(
      media,
      boundary.width,
      boundary.height,
    )})`;
  });
};

const getDefaultImageStyle = fullWidth => ({
  backgroundPosition: 'center',
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  backgroundClip: 'content-box',
  padding: fullWidth ? 0 : 10,
});

const getFirstRow = (backgroundImages: any[], fullWidth: boolean) => {
  return (
    <div style={{ flex: 3, display: 'flex', flexDirection: 'row' }}>
      <div
        className="img"
        style={{
          ...getDefaultImageStyle(fullWidth),
          backgroundImage: backgroundImages[0],
          flex: 3,
        }}
      />
      <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
        <div
          className="img"
          style={{
            ...getDefaultImageStyle(fullWidth),
            backgroundImage: backgroundImages[1],
            flex: 1,
          }}
        />
        <div
          className="img"
          style={{
            ...getDefaultImageStyle(fullWidth),
            backgroundImage: backgroundImages[2],
            flex: 2,
          }}
        />
      </div>
    </div>
  );
};

const getSecondRow = (backgroundImages, fullWidth) => {
  return (
    <div style={{ flex: 2, display: 'flex', flexDirection: 'row' }}>
      <div
        className="img"
        style={{
          ...getDefaultImageStyle(fullWidth),
          backgroundImage: backgroundImages[3],
          flex: 1,
        }}
      />
      <div
        className="img"
        style={{
          ...getDefaultImageStyle(fullWidth),
          backgroundImage: backgroundImages[4],
          flex: 1,
        }}
      />
    </div>
  );
};

const getContainerStyle = (
  boundary: Iboundary,
  fullWidth: boolean,
): React.CSSProperties => {
  const style: React.CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
    height: boundary.height,
  };

  if (!fullWidth) {
    style.width = boundary.width;
  }

  return style;
};

export const Collage: React.FunctionComponent<CollageProps> = ({
  boundary,
  value,
  override,
}) => {
  value = _.filter(value, { mediaType: MediaItemTypes.Image });
  const amountOfAvailableImages = value.length;
  const fullWidth = override.name === 'Fullwidth Collage';
  const backgroundImages = getBackgroundImages(
    amountOfAvailableImages > 4 ? 5 : amountOfAvailableImages,
    value,
    boundary,
  );

  return (
    <div
      className={classNames({
        'collage-media-layout-preview': true,
        'strip-media-layout-preview': fullWidth,
      })}
      style={getContainerStyle(boundary, fullWidth)}
    >
      {getFirstRow(backgroundImages, fullWidth)}
      {value.length > 3 && getSecondRow(backgroundImages, fullWidth)}
    </div>
  );
};
