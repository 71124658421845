import * as React from 'react';
import * as _ from 'lodash';
import {
  designKit,
  componentStyles,
  previewColors,
  modelStyles,
  LayoutElement,
  userOverrides,
  applicationParameters,
  Flags,
} from '@wix/adi-core-santa-layer';
import {
  convertToNumber,
  getCatalogStyleDefinition,
} from '../../../../utils/utils';

interface ContainerProps {
  layout: any;
  le: LayoutElement;
  kit: any;
}

interface Icolors {
  backgroundColor: string | undefined;
  borderColor: string | undefined;
}

const getStyle = (
  borderWidth: number,
  backgroundColor?: string,
  borderColor?: string,
): React.CSSProperties => {
  const style: React.CSSProperties = {
    position: 'absolute' as 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    width: 'auto',
    height: 'auto',
  };

  if (backgroundColor) {
    style.backgroundColor = backgroundColor;
  }

  if (borderColor) {
    style.borderColor = borderColor;
  }

  if (borderWidth) {
    style.borderWidth = borderWidth;
  }

  if (borderWidth || borderColor) {
    style.borderStyle = 'solid';
  }

  return style;
};

const getBorderWidth = (styleId: string): number | undefined => {
  const styleDef = getCatalogStyleDefinition(styleId);

  const value = convertToNumber(styleDef.properties.brw);
  if (styleDef.properties['alpha-brd'] === '1' && value > 0) {
    return value;
  } else {
    return;
  }
};

const noProp = (styleProp, _property) =>
  !styleProp[_property] || styleProp[_property] === '0';
const noBg = styleProp => noProp(styleProp, 'alpha-bg');
const noBorder = styleProp => noProp(styleProp, 'alpha-brd');

const getElementColor = (styleDefProp: string, boxStyleDef, kit: any) => {
  let color;
  const santaColor = _.get(boxStyleDef, ['style', 'properties', styleDefProp]);
  if (santaColor) {
    color = previewColors.getRGBColor(santaColor, { kit });
  }
  return color;
};

const getColors = (styleId, layout, le, kit): Icolors => {
  const colors = {
    backgroundColor: undefined,
    borderColor: undefined,
  };

  if (layout.containerRole === 'decorative' && Flags.designKit) {
    const styleKey =
      (layout.subType || layout.type) +
      componentStyles.getDecorationUniqueID(layout);
    const colorStyleDef = modelStyles.getDecorativeBoxStyleDefinition(
      le,
      styleId,
      styleKey,
    );
    const shouldSkip =
      le.containingSegment.hasColumnWithColorBackground() &&
      !le.isOnMediaContainer(layout);
    const styleProps = colorStyleDef.style.properties;
    const bgOverride =
      applicationParameters.isInMobileEditMode() &&
      userOverrides.getColumnBgColorOverride(le.containingSegment);
    if (bgOverride) {
      styleProps.bg = designKit.getSantaFormatColor(bgOverride);
    }

    if (!noBg(styleProps)) {
      colors.backgroundColor = shouldSkip
        ? 'transparent'
        : getElementColor('bg', colorStyleDef, kit);
    }

    if (!noBorder(styleProps)) {
      colors.borderColor = shouldSkip
        ? 'transparent'
        : getElementColor('brd', colorStyleDef, kit);
    }
  }
  return colors;
};

export const Container: React.FunctionComponent<ContainerProps> = ({
  layout,
  le,
  kit,
}) => {
  const styleId = layout.style;

  if (styleId === 'c100') {
    return <div />;
  }

  const borderWidth = getBorderWidth(styleId);
  const {
    backgroundColor,
    borderColor,
  }: { backgroundColor: any; borderColor: any } = getColors(
    styleId,
    layout,
    le,
    kit,
  );

  //border color and background color on ref is an hack to allow to add important (as in original code)
  //should be removed if possible
  return (
    <div
      style={getStyle(borderWidth)}
      ref={(el: any) => {
        if (el && backgroundColor) {
          el.style.setProperty(
            'background-color',
            backgroundColor,
            'important',
          );
        }

        if (el && borderColor) {
          el.style.setProperty('border-color', borderColor, 'important');
        }
      }}
    />
  );
};
