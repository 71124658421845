import * as React from 'react';
import * as _ from 'lodash';
import { mediaGallery } from '@wix/adi-core-santa-layer';
import { MediaItemTypes } from '@wix/adi-core-types';
import classNames from 'classnames';

interface Iboundary {
  height: number;
  width: number;
}

interface SliderProps {
  boundary: Iboundary;
  value: any;
  override: any;
}

const getBackgroundImage = (i: number, value, boundary) => {
  const media = value[i] || value[value.length - 1];
  return `url(${mediaGallery.itemToThumbnailUrl(
    media,
    boundary.width,
    boundary.height,
  )})`;
};

const getImageStyle = (i: number, fullWidth: boolean, boundary, value) => ({
  backgroundPosition: 'center',
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  backgroundClip: 'content-box',
  padding: '0 5px',
  opacity: fullWidth && i !== 1 ? 0.6 : 1,
  backgroundImage: getBackgroundImage(i, value, boundary),
});

const getContainerStyle = (
  boundary: Iboundary,
  fullWidth: boolean,
): React.CSSProperties => {
  const style: React.CSSProperties = {
    display: 'flex',
    flexDirection: 'row',
    height: boundary.height,
  };

  if (!fullWidth) {
    style.width = boundary.width;
  }

  return style;
};

export const Slider: React.FunctionComponent<SliderProps> = ({
  boundary,
  value,
  override,
}) => {
  value = _.filter(value, { mediaType: MediaItemTypes.Image });
  const fullWidth = override.name === 'Strip Showcase';

  return (
    <div
      className={classNames({
        'slider-media-layout-preview': true,
        'strip-media-layout-preview': fullWidth,
      })}
      style={getContainerStyle(boundary, fullWidth)}
    >
      <div
        className="img"
        style={{ flex: 1, ...getImageStyle(0, fullWidth, boundary, value) }}
      />
      <div
        className="img"
        style={{ flex: 2, ...getImageStyle(1, fullWidth, boundary, value) }}
      />
      <div
        className="img"
        style={{ flex: 1, ...getImageStyle(2, fullWidth, boundary, value) }}
      />
    </div>
  );
};
