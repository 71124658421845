import {
  ContentManager,
  ICmOptions,
  ISuggestResult,
  ISectionBrief,
  CeType,
} from '@wix/adi-content-api';

export const caas = {
  lang: 'en',
  caas: new ContentManager({
    locale: 'en',
    origin: 'playground',
    contentVersion: undefined, // TODO fix it
  } as ICmOptions),
  setLang(lang: string) {
    this.lang = lang;
    this.caas = new ContentManager({
      locale: this.lang,
      origin: 'PLAYGROUND',
      contentVersion: undefined, // TODO fix it
    } as ICmOptions);
  },
  initContent(industryId: string, structureId: string, replacements: any) {
    return this.caas.initContent(industryId, structureId, replacements);
  },
  suggest(
    value: string,
    maxResults?: number,
    maxRelatedResults?: number,
  ): Promise<ISuggestResult> {
    return this.caas.suggest(value, maxResults, maxRelatedResults);
  },
  getPages() {
    return this.caas.getPages();
  },
  generateContentItems(
    data: ISectionBrief | CeType,
    count?: number,
    randomOffset?: number,
  ) {
    return this.caas.generateContentItems(data, count, randomOffset);
  },
  generateListContentItems(
    data: ISectionBrief | CeType,
    count?: number,
    cardinality?: number,
    randomOffset?: number,
  ) {
    return this.caas.generateListContentItems(
      data,
      count,
      cardinality,
      randomOffset,
    );
  },
};

export const suggestSaas = {
  lang: 'en',
  caas: new ContentManager({
    locale: 'en',
    origin: 'playground',
    contentVersion: undefined, // TODO fix it
  } as ICmOptions),
  setLang(lang: string) {
    this.lang = lang;
    this.caas = new ContentManager({
      locale: this.lang,
      origin: 'PLAYGROUND',
      contentVersion: undefined, // TODO fix it
    } as ICmOptions);
  },
  suggest(
    value: string,
    maxResults?: number,
    maxRelatedResults?: number,
  ): Promise<ISuggestResult> {
    return this.caas.suggest(value, maxResults, maxRelatedResults);
  },
};

// export const fakeCaas = {
//   setLang: (lang: string) => { },
//   suggest(
//     value: string,
//     maxResults?: number,
//     maxRelatedResults?: number,
//   ): Promise<ISuggestResult> {
//     return new Promise(res => {
//       setTimeout(() => {
//         res(fakeSuggestResponse);
//       }, 300);
//     });
//   },
//   initContent(
//     industryId: string,
//     structureId: string,
//     replacements: any,
//     useDefaultCategory?: boolean,
//     useDefaultContent?: boolean,
//   ): Promise<any> {
//     return new Promise(res => {
//       setTimeout(() => {
//         res();
//       }, 300);
//     });
//   },
//   getPages(): IPageDescription[] | undefined {
//     return fakeLookupPayload.pages;
//   },
//   generateContentItems(
//     data: ISectionBrief | CeType,
//     count?: number,
//     randomOffset: number = 0,
//   ): ContentItem[] | undefined {
//     return Array(count || 1)
//       .fill(null)
//       .map((it, index) => generateItem(index + randomOffset));
//   },
//   generateListContentItems(
//     data: ISectionBrief | CeType,
//     count?: number,
//     cardinality?: number,
//     randomOffset: number = 0,
//   ): ListContentItem[] | undefined {
//     return Array(count || 1)
//       .fill(null)
//       .map(
//         (it, index) =>
//           ({
//             items: Array(cardinality || 1)
//               .fill(null)
//               .map((it2, index2) =>
//                 generateItem(index + index2 + randomOffset),
//               ),
//             header: generateItem(index + randomOffset, 'header'),
//             footer: generateItem(index + randomOffset, 'footer'),
//           } as ListContentItem),
//       );
//   },
// };
