import * as React from 'react';
import * as _ from 'lodash';
import {
  designKit,
  componentStyles,
  previewColors,
  LayoutElement,
  Flags,
} from '@wix/adi-core-santa-layer';
import { ElementTypes } from '@wix/adi-core-types';
import {
  convertToNumber,
  getCatalogStyleDefinition,
} from '../../../../utils/utils';

const getBorderColor = (styleId, layout, le, kit): string | undefined => {
  let brdColor;
  if (Flags.designKit) {
    const styleKey =
      (layout.subType || layout.type) +
      componentStyles.getDecorationUniqueID(layout);
    const colorStyleDef = designKit.getLineColoredStyleDefinition(
      styleId,
      le,
      styleKey,
      layout,
    );
    brdColor = _.get(colorStyleDef, ['style', 'properties', 'brd']);
    if (brdColor) {
      brdColor = previewColors.getRGBColor(brdColor, { kit });
    }
  }
  return brdColor;
};

const getBorderBottomValue = (styleId: string): number | undefined => {
  const styleDef = getCatalogStyleDefinition(styleId);

  if (!(styleDef && styleDef.properties)) {
    return;
  }

  const value = convertToNumber(styleDef.properties.lnw);
  if (styleDef.properties['alpha-brd'] === '1' && value > 0) {
    return value;
  } else {
    return;
  }
};

const isMobileLeInConsumerMode = (le): boolean =>
  le.isMobile() && Flags.consumerMode;

const isDesktopLineLayout = (le): boolean => {
  const desktopLe = le.containingSegment.getDesktopSegment().getLE();
  const desktopLineLayout = _.find(
    desktopLe && desktopLe.layout,
    (l: any) => l.type === ElementTypes.Line,
  );
  return !!desktopLineLayout;
};

interface Istyle {
  top: number | string;
  left: number | string;
  width: number | string;
  display: string;
  borderColor?: string;
  borderBottomWidth?: number | string;
}

const getStyle = (borderBottomWidth: number, borderColor?: string): Istyle => {
  const style: Istyle = {
    top: 0,
    left: 0,
    width: '100%',
    display: 'block',
  };

  if (borderBottomWidth) {
    style.borderBottomWidth = borderBottomWidth;
  }

  if (borderColor) {
    style.borderColor = borderColor;
  }
  return style;
};

interface LineProps {
  layout: any;
  le: LayoutElement;
  kit: any;
}

export const Line: React.FunctionComponent<LineProps> = ({
  layout,
  le,
  kit,
}) => {
  if (isMobileLeInConsumerMode(le) && !isDesktopLineLayout(le)) {
    return <div />;
  }
  const styleId = layout.style;
  const borderColor: string = getBorderColor(styleId, layout, le, kit);
  const borderBottomWidth: number | undefined = getBorderBottomValue(styleId);

  //border color on ref is an hank to allow to add important (as in original code)
  //should be removed if possible
  return (
    <div
      style={getStyle(borderBottomWidth)}
      ref={el => {
        if (el && borderColor) {
          el.style.setProperty('border-color', borderColor, 'important');
        }
      }}
    />
  );
};
