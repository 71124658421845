import * as React from 'react';
import * as _ from 'lodash';
import { mediaGallery } from '@wix/adi-core-santa-layer';
// import * as s from './Strip.scss';

interface StripProps {
  layout: any;
  stripWidth: number;
  value: any;
  override: any;
  le: any;
}

const getSlideshowItems = (amountOfItems: number): JSX.Element[] =>
  _.range(amountOfItems).map((i, index) => (
    <div key={index} className="strip-slideshow-navbar-item" />
  ));

const getSlideshow = (): JSX.Element => {
  return <div className="strip-slideshow-navbar">{getSlideshowItems(5)}</div>;
};

const getAlignmentOptions = (stripMediaItem, override, le) => {
  let alignmentOptions;
  if (override.properties.alignType) {
    alignmentOptions = {
      alignment: override.properties.alignType,
    };
  }
  const focalPoint =
    le.containingSegment &&
    le.containingSegment.node &&
    le.containingSegment.node.getFocalPoint(stripMediaItem.relativeUri);

  if (focalPoint) {
    alignmentOptions = { focalPoint };
  }

  return alignmentOptions;
};

const getContainerStyle = (
  layout,
  stripWidth,
  stripMediaItem,
  override,
  le,
): React.CSSProperties => {
  const alignmentOptions = getAlignmentOptions(stripMediaItem, override, le);
  const imageUrl = mediaGallery.itemToThumbnailUrl(
    stripMediaItem,
    stripWidth,
    layout.height,
    alignmentOptions,
  );

  return {
    height: layout.height,
    backgroundImage: `url(${imageUrl})`,
  };
};

const isStripSlideshow = (override): boolean =>
  override.name === 'Strip Slideshow';

export const Strip: React.FunctionComponent<StripProps> = ({
  layout,
  stripWidth,
  value,
  override,
  le,
}) => {
  const stripMediaItem = value[0];

  return (
    <div
      className="strip-media-layout-preview"
      style={getContainerStyle(
        layout,
        stripWidth,
        stripMediaItem,
        override,
        le,
      )}
    >
      {isStripSlideshow(override) && getSlideshow()}
    </div>
  );
};
