import * as React from 'react';
import {
  LayoutElement,
  visualValidationService,
} from '@wix/adi-core-santa-layer';
import { emptyElement, applyTextStyle } from './textUtils';
// import * as s from './Text.scss';

interface TextProps {
  layout: any;
  le: LayoutElement;
  value: any;
  kit: any;
}

interface TextState {
  textComponent: null | HTMLElement;
}

export class Text extends React.Component<TextProps, TextState> {
  constructor(props: TextProps) {
    super(props);
    this.state = {
      textComponent: null,
    };
  }

  componentDidMount() {
    const { le, layout, value, kit } = this.props;
    visualValidationService.getOrBuildValidator(layout, le).then(validator => {
      if (!validator.div) {
        return;
      }
      const clone = validator.div.cloneNode(true);
      const innerChild = visualValidationService.innerChild(clone);

      emptyElement(innerChild);
      applyTextStyle(clone, innerChild, le, layout, kit, value);
      clone.classList.add('text-container');

      this.setState({ textComponent: clone });
    });
  }

  render() {
    if (this.state.textComponent) {
      return (
        <div
          ref={node => {
            if (node) {
              node.appendChild(this.state.textComponent);
            }
          }}
        />
      );
    } else {
      return <div style={{ color: 'white' }}>LOADING</div>;
    }
  }
}
