import * as React from 'react';
import { mediaGallery } from '@wix/adi-core-santa-layer';

interface Iboundary {
  height: number;
  width: number;
}

interface SlideshowProps {
  boundary: Iboundary;
  value: any;
}

const getImageStyle = (media, boundary): React.CSSProperties => ({
  flex: 1,
  height: '100%',
  backgroundPosition: 'center',
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  backgroundClip: 'content-box',
  backgroundImage: `url(${mediaGallery.itemToThumbnailUrl(
    media,
    boundary.width,
    boundary.height,
  )})`,
});

const getContainerStyle = (boundary: Iboundary): React.CSSProperties => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  height: boundary.height,
  width: boundary.width,
});

const svgArrowLeft = (
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    width="12.8px"
    height="25.5px"
    viewBox="29.5 13.9 12.8 25.5"
    style={{
      margin: '0 30px 0 20px',
    }}
    xmlSpace="preserve"
  >
    <path d="M41,39.5l1.2-1.3L30.5,25.4l-1.2,1.3L41,39.5z" />
    <path d="M42.2,15.2L41,13.9L29.4,26.7l1.2,1.3L42.2,15.2z" />
  </svg>
);

const svgArrowRight = (
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    width="12.8px"
    height="25.5px"
    viewBox="29.5 13.9 12.8 25.5"
    style={{ margin: '0 20px 0 30px' }}
    xmlSpace="preserve"
  >
    <path d="M30.5,39.5l-1.2-1.3L41,25.4l1.2,1.3L30.5,39.5z" />
    <path d="M29.4,15.2l1.2-1.3l11.6,12.8L41,28L29.4,15.2z" />
  </svg>
);

export const Slideshow: React.FunctionComponent<SlideshowProps> = ({
  boundary,
  value,
}) => {
  const media = value[0];

  return (
    <div
      className="impress-media-layout-preview"
      style={getContainerStyle(boundary)}
    >
      {svgArrowLeft}
      <div className="img" style={getImageStyle(media, boundary)} />
      {svgArrowRight}
    </div>
  );
};
