import * as React from 'react';
import * as _ from 'lodash';
import { mediaGallery } from '@wix/adi-core-santa-layer';
import { MediaItemTypes } from '@wix/adi-core-types';

interface Iboundary {
  height: number;
  width: number;
}

interface ThumbnailsProps {
  boundary: Iboundary;
  value: any;
  override: any;
}

const getBackgroundImages = (amountImages: number, value, boundary) => {
  return _.range(amountImages).map((el, i) => {
    const media = value[i] || value[value.length - 1];
    return `url(${mediaGallery.itemToThumbnailUrl(
      media,
      boundary.width,
      boundary.height,
    )})`;
  });
};

const getContainerStyle = (
  boundary,
  thumbnailsGravity,
): React.CSSProperties => ({
  display: 'flex',
  flexDirection:
    thumbnailsGravity === 'bottom'
      ? 'column'
      : thumbnailsGravity === 'right'
      ? 'row'
      : 'row-reverse',
  height: boundary.height,
  width: boundary.width,
});

const getFirstImageStyle = (boundary, value): React.CSSProperties => ({
  flex: 1,
  backgroundImage: `url(${mediaGallery.itemToThumbnailUrl(
    value[0],
    boundary.width,
    boundary.height,
  )})`,
});

const getImagesContainerStyle = (thumbnailsGravity): React.CSSProperties => ({
  display: 'flex',
  justifyContent: 'center',
  flexDirection: thumbnailsGravity === 'bottom' ? 'row' : 'column',
});

const getDefaultImageStyle = (width, height) => ({
  backgroundPosition: 'center',
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  backgroundClip: 'content-box',
  padding: '5px',
  width,
  height,
});

export const Thumbnails: React.FunctionComponent<ThumbnailsProps> = ({
  boundary,
  value,
  override,
}) => {
  value = _.filter(value, { mediaType: MediaItemTypes.Image });
  const width = 80;
  const height = 60;
  const thumbnailsGravity =
    (override.properties && override.properties.thumbnailsGravity) || 'bottom';
  const backgroundImages: any[] = getBackgroundImages(3, value, boundary);

  return (
    <div
      className="thumbnails-media-layout-preview"
      style={getContainerStyle(boundary, thumbnailsGravity)}
    >
      <div style={getFirstImageStyle(boundary, value)} />
      <div style={getImagesContainerStyle(thumbnailsGravity)}>
        <div
          className="img"
          style={{
            ...getDefaultImageStyle(width, height),
            backgroundImage: backgroundImages[0],
          }}
        />
        <div
          className="img"
          style={{
            ...getDefaultImageStyle(width, height),
            backgroundImage: backgroundImages[1],
          }}
        />
        <div
          className="img"
          style={{
            ...getDefaultImageStyle(width, height),
            backgroundImage: backgroundImages[2],
          }}
        />
      </div>
    </div>
  );
};
