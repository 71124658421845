import * as _ from 'lodash';
import {
  IPresetPreview,
  applicationParameters,
  mediaGallery,
  previewColors,
  themeChooser,
  designKit,
  columnService,
  slideShowService,
  mediaOpacity,
  LayoutGroup,
  selections,
  isLightboxType,
  Flags,
} from '@wix/adi-core-santa-layer';
import {
  ElementTypes,
  MediaItemTypes,
  ElementSubTypes,
} from '@wix/adi-core-types';

import {
  getLayoutBoundary,
  calculateStripHeight,
  getPartialColumnContentOffset,
  stripBackgroundThumbnail,
  getParentWithClass,
  getPageBgColor,
} from '../utils';
import { LayoutPreview } from '../../LayoutPreview/LayoutPreview';
import { VideoBg } from '../../LayoutPreview/LayoutPreviewComponents/Media/MediaComponents/StripVideo/VideoBg/VideoBg';
import {
  MOBILE_WIDTH,
  COLUMN_ALIGNMENT,
  PAGE_WIDTH,
} from '../SinglePresetPreview';
import {
  getDomElementFromReactComponent,
  getDomElementFromReactComponentParent,
} from '../../../utils/utils';

const LIGHTBOX_PADDING = 20;
const LIGHTBOX_MOBILE_PADDING = 5;

const isLightbox = presetPreview =>
  isLightboxType(presetPreview.node.contentElementType);

export const renderComponents = (
  presetPreview: IPresetPreview,
  width: number,
  lgPreviewsPromises: Promise<any>[],
  kit: any,
  translate,
): HTMLElement => {
  const { node, solution } = presetPreview;
  const lgDataArray = presetPreview.lgDataArray || [];
  const stripSizes = presetPreview.stripSizes || [];

  //container
  const container: HTMLElement = document.createElement('div');
  container.style.width = `${width}px`;
  container.style.height = 'auto';
  container.style.backgroundColor = getPageBgColor(solution, node);

  const lightboxData =
    isLightbox(presetPreview) &&
    (presetPreview.preset?.data?.lightbox ||
      selections.getForNode(presetPreview.node)?.lightbox);

  if (isLightbox(presetPreview)) {
    const lightboxMargin = 40;

    if (lightboxData?.position === 'center') {
      container.style.padding = `${lightboxMargin}px 0`;
    } else if (lightboxData?.position === 'top') {
      container.style.padding = `0 0 ${lightboxMargin * 2}px`;
    } else if (lightboxData?.position === 'bottom') {
      container.style.padding = `${lightboxMargin * 2}px 0 0`;
    }
  }

  getStrips(
    lgDataArray,
    container,
    stripSizes,
    solution,
    presetPreview,
    lgPreviewsPromises,
    kit,
    lightboxData,
  );

  if (solution[0] && solution[0].isSiteHeader() && solution[0].isMobile()) {
    renderMenuContainerPreview(solution, container, kit, translate);
  }
  return container;
};

const renderMenuContainerPreview = (
  solution,
  element: HTMLElement,
  kit: any,
  translate,
): Promise<void> => {
  const pageListMarginTop = 80;
  const pageListHeight = 160;
  const pageListFont = 'font_7';
  const bgColorSanta =
    (Flags.designKit && designKit.getHeaderBg(solution)) || 1;
  const bgColor = previewColors.getRGBColor(bgColorSanta, { kit });
  const fontColor = previewColors.getRGBColor(
    designKit.invertToBrightestColor(`{${bgColorSanta}}`),
    { kit },
  );

  const promise = Promise.resolve();
  const le = solution[0].segments[0].getLE();
  const menuContainerLayout = le.layout.find(
    l => l.subType === 'menuContainer',
  );
  let layouts;
  let listPageLayout;
  if (menuContainerLayout) {
    const pagesListLayoutIndex = menuContainerLayout.layout.findIndex(
      l => l.subType === 'pagesList',
    );
    layouts = _.cloneDeep(menuContainerLayout.layout);

    if (pagesListLayoutIndex !== -1) {
      listPageLayout = layouts.splice(pagesListLayoutIndex, 1)[0];

      //adjust layout to pageList
      const listPageBottomFromSolution =
        listPageLayout.top + listPageLayout.height;
      const listPageBottomFromSpec = pageListMarginTop + pageListHeight;
      const diff = listPageBottomFromSpec - listPageBottomFromSolution;

      if (diff !== 0) {
        layouts.forEach(layout => (layout.top += diff));
      }
    }
  }

  //menu-preview-container
  const previewContainer = document.createElement('div');
  previewContainer.classList.add('menu-preview-container');
  previewContainer.style.backgroundColor = bgColor || '#FFFFFF';

  //menu-preview-close-button
  const closeButton = document.createElement('div');
  closeButton.classList.add('menu-preview-close-button');
  closeButton.style.color = fontColor || '#000000';

  //menu-preview-layout-container
  const layoutContainer = document.createElement('div');
  layoutContainer.classList.add('menu-preview-layout-container');

  //menu-preview-pages-list-container
  const pageListElement = document.createElement('div');
  pageListElement.classList.add('menu-preview-pages-list-container');
  pageListElement.innerHTML = `<div class="menu-preview-pages-list-item">${translate.instant(
    'page-preset-previews.mobile-menu.pages-list.home',
  )}</div><div class="menu-preview-pages-list-item">${translate.instant(
    'page-preset-previews.mobile-menu.pages-list.welcome',
  )}</div><div class="menu-preview-pages-list-item">${translate.instant(
    'page-preset-previews.mobile-menu.pages-list.about',
  )}</div>`;
  pageListElement.style.top = `${pageListMarginTop}px`;
  pageListElement.style.height = `${pageListHeight}px`;
  pageListElement.style.font = themeChooser.getFontStyle(pageListFont);
  pageListElement.style.color = fontColor || '#000000';

  promise.then(() => {
    if (layouts) {
      renderLEPreview(le, layouts, layoutContainer, null, null, kit);
    }
  });

  previewContainer.append(closeButton);
  layoutContainer.append(pageListElement);
  previewContainer.append(layoutContainer);
  element.append(previewContainer);

  return Promise.all([promise]) as any;
};

const renderLEPreview = (
  le,
  layouts,
  element,
  elementParent,
  rootElWidth,
  kit: any,
  segToStripOffset?,
  columnsWidth?,
): Promise<void> => {
  // workaround to keep media up-to-date
  le.containingSegment.contentElement.fillValuesByMediaType();

  let promise = Promise.resolve();
  const childPromises = [];
  layouts.forEach(layout => {
    if (layout.subType === 'menuContainer') {
      return;
    }

    const boundary = getLayoutBoundary(layout, le, columnsWidth);
    const layoutPreview = document.createElement('div');
    layoutPreview.classList.add('layout-preview');
    layoutPreview.style.height = `${boundary.height}px`;
    layoutPreview.style.width = `${boundary.width}px`;
    layoutPreview.style.top = `${boundary.top}px`;
    layoutPreview.style.left = `${boundary.left}px`;

    promise = promise
      .then(() =>
        (Promise.resolve(
          getDomElementFromReactComponent(LayoutPreview, {
            layout,
            le,
            width: rootElWidth,
            boundary,
            kit,
          }),
        ) as Promise<Element | any[]>).then(clones => {
          if (clones) {
            clones = Array.isArray(clones) ? clones : [clones];
            clones.forEach(clone => {
              if (le.isMapStripType(layout)) {
                layoutPreview.style.left = _.get(layout, 'docked.left.px', 0);
                layoutPreview.style.right = _.get(layout, 'docked.right.px', 0);
                layoutPreview.style.position = 'absolute';
                layoutPreview.style.width = 'auto';

                clone.style.width = 'auto';

                if (element.classList.contains('column-preview')) {
                  element.style.width = '100%';
                } else {
                  element.style.position = 'static';
                  if (elementParent) {
                    elementParent.style.position = 'static';
                  }
                }
              }

              if (clone.classList.contains('strip-media-layout-preview')) {
                const opacity = mediaOpacity.getOpacity(
                  le.containingSegment.node,
                );
                const bgColor = mediaOpacity.getColor(
                  le.containingSegment.node,
                );
                if (opacity < 1) {
                  if (bgColor) {
                    const mediaBgElement = document.createElement('div');
                    mediaBgElement.classList.add('media-bg');
                    mediaBgElement.classList.add('color-bg');
                    mediaBgElement.style.height = '100%';
                    mediaBgElement.style.width = '100%';
                    mediaBgElement.style.backgroundColor = previewColors.getRGBColor(
                      bgColor,
                    );
                    mediaBgElement.style.opacity = `${1 - opacity}`;

                    clone.prepend(mediaBgElement);
                  } else {
                    clone.style.opacity = opacity;
                  }
                }
                if (
                  !getParentWithClass(layoutPreview, 'column-preview').length
                ) {
                  layoutPreview.style.left = '0';
                }
                if (!isNaN(segToStripOffset)) {
                  clone.style.marginLeft = -segToStripOffset - layout.left;
                }
              }

              if (clone.classList.contains('media-layout-shadow-preview')) {
                const parentWithClass = getParentWithClass(
                  layoutPreview,
                  'column-container-preview',
                );
                parentWithClass.forEach(
                  parent => (parent.style.overflow = 'visible'),
                );
              }

              if (layout.type === ElementTypes.Map) {
                let mapStyle = le.getMapStyleOverride(layout);
                if (!mapStyle) {
                  mapStyle = designKit.getDefaultMapStyle(le, layout);
                }
                switch (mapStyle) {
                  case 'white':
                    clone.classList.add('map-layout-preview-light');
                    break;
                  case 'black':
                    clone.classList.add('map-layout-preview-dark');
                }
              }

              clone.classList.add(
                (layout.type || '').toLowerCase() + '-layout-preview',
              );
              layoutPreview.prepend(clone);
            });
          } else {
            layoutPreview.style.backgroundColor = 'blue';
            const textNode = document.createTextNode(layout.type);
            layoutPreview.append(textNode);
          }
        }),
      )
      .catch(e => {
        /* ignore rejection */
        if (Flags.debug) {
          console.log('Error Preset Preview: ', e);
        }
      });

    if (layout.layout) {
      childPromises.push(
        renderLEPreview(
          le,
          layout.layout,
          layoutPreview,
          null,
          rootElWidth,
          kit,
          segToStripOffset,
        ),
      );
    }
    element.append(layoutPreview);
  });

  return Promise.all([promise, ...childPromises]) as any;
};

const renderPartialMediaBackground = (
  stripPreview: HTMLElement,
  stripData,
  mediaItem,
  width: number,
  height: number,
  alignType?,
  opacity?,
  bgColor?: string,
  mediaMargin?,
) => {
  const partialMediaWidthUnits = stripData.partialWidth;
  const mediaWidth = (partialMediaWidthUnits / 12) * width;

  const partialMediaPosition = stripData.partialMediaPosition;
  let left = 0;

  if (partialMediaPosition === 'right') {
    left = width - mediaWidth;
  }

  renderMediaBackground(
    stripPreview,
    mediaItem,
    mediaWidth,
    height,
    alignType,
    opacity,
    bgColor,
    mediaMargin,
    left,
  );
};

const renderMediaBackground = (
  el: HTMLElement,
  mediaItem,
  width: number,
  height: number,
  alignType?,
  opacity?: number,
  bgColor?: string,
  mediaMargin?,
  left = 0,
  focalPoint?,
) => {
  if (mediaItem.mediaType === MediaItemTypes.Video) {
    el.prepend(
      getDomElementFromReactComponentParent(VideoBg, {
        mediaItem,
        width,
        height,
        left,
      }),
    );
  } else {
    let mediaHeight = height;
    if (mediaMargin) {
      mediaHeight = mediaHeight - (mediaMargin.top || mediaMargin.bottom || 0);
    }

    //mediaBg
    const mediaBgElement = document.createElement('div');
    mediaBgElement.style.width = `${width}px`;
    mediaBgElement.style.left = `${left}px`;
    mediaBgElement.style.height = `${mediaHeight}px`;
    mediaBgElement.style.opacity = `${opacity || 1}`;
    mediaBgElement.style.marginTop = mediaMargin && `${mediaMargin.top}px`;
    mediaBgElement.style.marginBottom =
      mediaMargin && `${mediaMargin.bottom}px`;
    mediaBgElement.style.backgroundImage = `url(${mediaGallery.itemToThumbnailUrl(
      mediaItem,
      width,
      mediaHeight,
      {
        alignment: alignType,
        focalPoint,
      },
    )})`;
    mediaBgElement.classList.add('media-bg');
    mediaBgElement.classList.add(`${mediaItem.mediaType}-bg`);
    el.prepend(mediaBgElement);

    if (bgColor) {
      //colorBg
      const colorBgElement = document.createElement('div');
      colorBgElement.style.width = `${width}px`;
      colorBgElement.style.height = `${height}px`;
      colorBgElement.style.backgroundColor = previewColors.getRGBColor(bgColor);
      colorBgElement.classList.add('media-bg');
      colorBgElement.classList.add('color-bg');
      el.prepend(colorBgElement);
    }
  }
};

const buildLgContainerElement = (lg: any): HTMLElement => {
  let contentElementType: string = '';

  const segment = lg.segments[0];
  if (segment && segment.node) {
    contentElementType = segment.node.contentElementType;
  }
  const lgContainerElement = document.createElement('div');
  lgContainerElement.classList.add('lg-container');
  lgContainerElement.setAttributeNS(
    null,
    'data-contentelementtype',
    contentElementType,
  ); //use NS to keep camel case
  return lgContainerElement;
};

const getMenuStrip = (
  menuStrip,
  lg: LayoutGroup,
  lgContainer: HTMLElement,
  baseStripPaddingTop,
  elementWidth: number,
  kit: any,
) => {
  const menuStripPaddingTop = menuStrip.paddingTop || 0;
  const menuStripPaddingBottom = menuStrip.paddingBottom || 0;
  const le = lg.segments[0].getLENoError();
  const menu = _.find(
    le.layout,
    (l: any) =>
      l.type === ElementTypes.Menu || l.subType === ElementSubTypes.Copyright,
  );
  if (menu) {
    let menuStripTop;
    let menuStripHeight;
    if (lg.isMobile() && lg.isSiteHeader()) {
      menuStripTop = le.menuStripTop;
      menuStripHeight = le.menuStripBottom - le.menuStripTop;
    } else {
      menuStripTop = menu.top - menuStripPaddingTop;
      menuStripHeight =
        menuStripPaddingTop + menuStripPaddingBottom + menu.height;
    }

    if (!isNaN(menuStripTop) && !isNaN(menuStripHeight)) {
      const menuStripPreview = document.createElement('div');
      menuStripPreview.classList.add('menu-strip-preview');
      menuStripPreview.style.height = `${menuStripHeight}px`;
      menuStripPreview.style.marginTop = `${baseStripPaddingTop +
        menuStripTop}px`;
      lgContainer.append(menuStripPreview);

      // set strip color
      menuStripPreview.style.backgroundColor = previewColors.getRGBColor(
        menuStrip.color || 'color_18',
        {
          kit,
        },
      );

      if (menuStrip.mediaRef) {
        // set strip media
        menuStripPreview.style.backgroundImage = `url(${stripBackgroundThumbnail(
          menuStrip.mediaRef,
          elementWidth,
          menuStripHeight,
        )})`;
      }
    }
  }
};

const getLE = (
  columnContainerPreview: HTMLElement,
  column,
  le,
  columnContainerWidth,
  columnHeight,
  columns,
  props,
  siteMargin,
  elementWidth,
  columnWidth,
  alignment,
  columnIndex,
  columnsRowOffset,
  strip,
  lePreviewsPromises,
  columnPreview,
  columnsWidth,
  kit: any,
) => {
  columnContainerPreview.setAttribute('le-id', le.id);

  let layouts = column.isVirtual || column.isMobile ? column.layout : le.layout;
  const columnBackground = columnService.createColumnBackground(column);

  if (columnBackground) {
    if (columnBackground.media) {
      layouts = _.filter(layouts, (l: any) => l.type !== ElementTypes.Media);
      const opacity = mediaOpacity.getOpacity(le.containingSegment.node);
      const bgColor = mediaOpacity.getColor(le.containingSegment.node);
      const mediaMargin =
        column.extraProps && column.extraProps.mediaBackgroundPadding
          ? column.extraProps.mediaBackgroundPadding
          : undefined;
      renderMediaBackground(
        columnContainerPreview,
        columnBackground.media,
        columnContainerWidth,
        columnHeight,
        undefined,
        opacity,
        bgColor,
        mediaMargin,
        0,
        le.containingSegment.getFocalPoint(),
      );
    } else if (columnBackground.color) {
      columnContainerPreview.style.backgroundColor = previewColors.getRGBColor(
        columnBackground.color,
        {
          opacity: columnBackground.colorOpacity || 1,
          kit,
        },
      );
    }
  }

  let segToStripOffset;
  if (column.isVirtual && columns.length === 1) {
    // only for one columns layout
    const siteMargin1 = props.fullWidth
      ? siteMargin
      : (elementWidth - PAGE_WIDTH) / 2;
    segToStripOffset =
      siteMargin1 +
      ((elementWidth - 2 * siteMargin1 - columnWidth) * alignment) / 100;
  }
  lePreviewsPromises.push(
    renderLEPreview(
      le,
      columnService.adjustLayoutForColumns(
        le,
        layouts,
        columns,
        columnIndex,
        columnsRowOffset,
        strip.inlinePaddingTop || 0,
        strip.inlinePaddingBottom || 0,
      ),
      columnPreview,
      columnContainerPreview,
      elementWidth,
      kit,
      segToStripOffset,
      columnsWidth,
    ),
  );
};

const getColumn = (
  column,
  columnHeight,
  lg,
  columns,
  columnIndex,
  columnsUnitWidth,
  floatingColumnOffset,
  columnsRowPreview: HTMLElement,
  elementWidth,
  lePreviewsPromises,
  props,
  columnsWidth,
  siteMargin,
  columnsRowOffset,
  strip,
  kit,
) => {
  const alignment = lg.isMobile()
    ? 50
    : columnService.getAlignment(columns, columnIndex);
  const columnContainerWidth = lg.isMobile()
    ? MOBILE_WIDTH
    : Math.floor(columnsUnitWidth * (column.renderedWidth || column.width));

  //column-container-preview
  const columnContainerPreview = document.createElement('div');
  columnContainerPreview.classList.add('column-container-preview');
  columnContainerPreview.style.height = `${columnHeight}px`;
  columnContainerPreview.style.width = `${columnContainerWidth}px`;
  columnContainerPreview.style.flex = '0 0 auto';
  columnContainerPreview.style.justifyContent = COLUMN_ALIGNMENT[alignment];
  columnContainerPreview.style.top = `${
    columnIndex % 2 ? floatingColumnOffset : 0
  }px`;
  columnsRowPreview.append(columnContainerPreview);

  //column-preview
  const columnWidth = lg.isMobile()
    ? applicationParameters.mobileFullWidth
    : columnService.calculateColumnWidth(columns, columnIndex);
  const columnPreview = document.createElement('div');
  columnPreview.classList.add('column-preview');
  columnPreview.style.height = `${columnHeight}px`;
  columnPreview.style.width = `${columnWidth}px`;

  columnContainerPreview.append(columnPreview);

  const le = column.segment && column.segment.getLENoError();
  if (le) {
    getLE(
      columnContainerPreview,
      column,
      le,
      columnContainerWidth,
      columnHeight,
      columns,
      props,
      siteMargin,
      elementWidth,
      columnWidth,
      alignment,
      columnIndex,
      columnsRowOffset,
      strip,
      lePreviewsPromises,
      columnPreview,
      columnsWidth,
      kit,
    );
  }
};

const getColumnRows = (
  columnsRows,
  lg: LayoutGroup,
  lgContainer: HTMLElement,
  elementWidth: number,
  strip,
  lgPreviewsPromises,
  kit: any,
) => {
  const rowsHeight = lg.isMobile()
    ? columnsRows.map(r => r[0].height)
    : columnService.getRowsHeight(lg);
  let columnsRowOffset = 0;
  const lastRowIndex = columnsRows.length - 1;
  const lePreviewsPromises = [] as Promise<void>[];
  const floatingColumnOffset = columnService.getFloatingColumnOffset(lg);
  columnsRows.forEach((columns, rowIndex) => {
    const columnsRowHeight = rowsHeight[rowIndex];
    const isStretchedMediaColumn = columnService.isStretchedMediaColumn(
      columns[0],
    );
    const nextIsStretchedMediaColumn =
      rowIndex < lastRowIndex &&
      columnsRows[lastRowIndex] &&
      columnService.isStretchedMediaColumn(columnsRows[lastRowIndex][0]);

    const columnHeight = isStretchedMediaColumn
      ? _.sumBy(rowsHeight)
      : columnsRowHeight;

    const columnsRowPreview = document.createElement('div');
    columnsRowPreview.classList.add('lg-preview');
    columnsRowPreview.style.height = `${columnHeight}px`;
    lgContainer.append(columnsRowPreview);

    const props = columnService.getColumnsContainerProps(columns);
    const siteMargin = lg.isMobile() ? 0 : props.siteMargin || 0;
    let columnsWidth;
    columnsRowPreview.classList.add('lg-columns-preview');
    if (isStretchedMediaColumn) {
      if (rowIndex > 0) {
        columnsRowPreview.style.marginTop = `${-1 *
          rowsHeight[rowIndex - 1]}px`;
      }
      if (rowIndex < columnsRows.length - 1) {
        columnsRowPreview.style.marginBottom = `${-1 *
          rowsHeight[rowIndex + 1]}px`;
      }
    }
    if (nextIsStretchedMediaColumn) {
      columnsRowPreview.style.zIndex = '100';
    }

    if (props.fullWidth) {
      columnsRowPreview.classList.add('lg-columns-full-width-preview');
      columnsRowPreview.style.paddingLeft = `${siteMargin}px`;
      columnsRowPreview.style.paddingRight = `${siteMargin}px`;
      columnsWidth = elementWidth - 2 * siteMargin;
    } else {
      columnsRowPreview.style.width = `${PAGE_WIDTH}px`;
      columnsWidth = PAGE_WIDTH;
    }
    const columnSpacing = props.columnsMargin;
    const columnsMargin = props.frameMargin;
    const columnsUnitWidth = lg.isHeader()
      ? 1
      : (columnsWidth -
          columnSpacing * (columns.length - 1) -
          2 * columnsMargin) /
        columnService.totalStructureColumns;

    columns.forEach((column: any, columnIndex: any) => {
      getColumn(
        column,
        columnHeight,
        lg,
        columns,
        columnIndex,
        columnsUnitWidth,
        floatingColumnOffset,
        columnsRowPreview,
        elementWidth,
        lePreviewsPromises,
        props,
        columnsWidth,
        siteMargin,
        columnsRowOffset,
        strip,
        kit,
      );
    });
    columnsRowOffset += columnsRowHeight;
  });
  lgPreviewsPromises.push(Promise.all(lePreviewsPromises) as any);
};

const getLgPreview = (
  lgContainer: HTMLElement,
  lgHeight: number,
  lg: LayoutGroup,
  strip,
  elementWidth: number,
  lgPreviewsPromises,
  kit: any,
) => {
  const lgPreview = document.createElement('div');
  lgPreview.classList.add('lg-preview');
  lgPreview.style.height = `${lgHeight}px`;
  lgContainer.append(lgPreview);

  let offsetPartial = 0;
  if (!lg.isMobile() && strip.partialContentPosition) {
    offsetPartial = getPartialColumnContentOffset(strip, elementWidth, lg);
  }

  const lePreviewsPromises = [] as Promise<void>[];
  lg.segments.forEach(segment => {
    getSegment(
      lgPreview,
      segment,
      offsetPartial,
      lePreviewsPromises,
      elementWidth,
      kit,
    );
  });

  lgPreviewsPromises.push(Promise.all(lePreviewsPromises) as any);
};

const getSegment = (
  lgPreview: HTMLElement,
  segment,
  offsetPartial,
  lePreviewsPromises,
  elementWidth: number,
  kit: any,
) => {
  const segmentPreview = document.createElement('div');
  segmentPreview.classList.add('segment-preview');
  segmentPreview.style.width = `${segment.width}px`;
  segmentPreview.style.height = `${segment.height}px`;
  segmentPreview.style.left = `${segment.left + offsetPartial}px`;

  lgPreview.append(segmentPreview);

  const le = segment.getLENoError();
  if (le) {
    segmentPreview.setAttribute('le-id', le.id);
    lePreviewsPromises.push(
      renderLEPreview(le, le.layout, segmentPreview, null, elementWidth, kit),
    );
  }
};

const getStripWithLg = (
  lg: LayoutGroup,
  lgDataArray: any[],
  index: number,
  slideShowPaddingTop: number,
  slideShowPaddingBottom: number,
  element: HTMLElement,
  stripSizes: any[],
  solution,
  presetPreview: IPresetPreview,
  lgPreviewsPromises,
  kit: any,
  stripPreview: HTMLElement,
  lightboxData?: any,
): HTMLElement => {
  const elementWidth: number = parseInt(element.style.width, 10);

  const lgData = lgDataArray[index] || lgDataArray[0] || {};
  const strip = lgData.strip || {};
  const menuStrip = lgData.menuStrip || {};
  const lgHeight = lg.getRenderedHeight();
  const spaceTop =
    (lgData.topLine &&
      lgData.topLine.enable &&
      lgData.topLine.line === 'space' &&
      lgData.topLine.space) ||
    0;
  const spaceBottom =
    (lgData.bottomLine &&
      lgData.bottomLine.enable &&
      lgData.bottomLine.line === 'space' &&
      lgData.bottomLine.space) ||
    0;
  const stripPaddingTop = slideShowPaddingTop || strip.paddingTop || 0;
  const stripPaddingBottom = slideShowPaddingBottom || strip.paddingBottom || 0;

  if (applicationParameters.isInMobileEditMode()) {
    element.classList.add('layout-preset-preview-mobile');
  }

  if (stripSizes[index]) {
    stripPreview = document.createElement('div');
    stripPreview.classList.add('strip-preview');
    element.append(stripPreview);

    if (applicationParameters.isInMobileEditMode()) {
      stripPreview.classList.add('strip-preview-mobile');
    }
    if (strip.enable) {
      // set strip color
      stripPreview.style.backgroundColor = previewColors.getRGBColor(
        strip.color || 'color_18',
        {
          kit,
        },
      );

      if (isLightbox(presetPreview)) {
        if (lightboxData?.position === 'center') {
          const width =
            lg.segments[0].width +
            2 * (lg.isMobile() ? LIGHTBOX_MOBILE_PADDING : LIGHTBOX_PADDING);
          stripPreview.style.width = `${width}px`;
          stripPreview.style.margin = '0 auto';
        }
      }

      // set strip media
      if (strip.mediaRef) {
        stripPreview.style.backgroundImage = `url(${stripBackgroundThumbnail(
          strip.mediaRef,
          elementWidth,
          calculateStripHeight(
            solution,
            lgDataArray,
            index,
            presetPreview.stripSizes[index],
          ),
        )})`;
      } else if (strip.mediaItems) {
        let opacity = strip.opacity;
        let bgColor = strip.color;
        if (strip.mediaType === MediaItemTypes.Image) {
          opacity = mediaOpacity.getOpacity(
            lg.segments[0].node.identifyingNode,
          );
          bgColor = mediaOpacity.getColor(lg.segments[0].node.identifyingNode);
        }

        const fullWidth = elementWidth;
        let stripSize;
        if (lg.isMobile() && strip && strip.partialMediaPosition) {
          stripSize = lg.isListHeader() ? 1 : 0;
        } else {
          stripSize = presetPreview.stripSizes[index];
        }

        if (stripSize) {
          const stripHeight = calculateStripHeight(
            solution,
            lgDataArray,
            index,
            stripSize,
          );

          const isPartialMedia =
            !lg.isMobile() && strip && strip.partialMediaPosition;
          if (isPartialMedia) {
            renderPartialMediaBackground(
              stripPreview,
              strip,
              strip.mediaItems[0],
              fullWidth,
              stripHeight,
              strip.alignType,
              opacity,
              bgColor,
            );
          } else {
            renderMediaBackground(
              stripPreview,
              strip.mediaItems[0],
              fullWidth,
              stripHeight,
              strip.alignType,
              opacity,
              bgColor,
            );
          }
        }
      }
    }
  }

  if (
    (stripPreview || element) &&
    !(stripPreview || element).querySelector('.slideshow-arrows')
  ) {
    const arrowsElement: string = addArrowsToSlideShow(presetPreview, lg, kit);
    if (arrowsElement !== '') {
      const arrowsDomElement = document.createElement('div');
      arrowsDomElement.innerHTML = arrowsElement;
      (stripPreview || element).append(arrowsDomElement);
    }
  }

  const lgContainer: HTMLElement = buildLgContainerElement(lg);
  (stripPreview || element).append(lgContainer);
  const baseStripPaddingTop = Math.max(stripPaddingTop, 0) + spaceTop;
  const baseStripPaddingBottom = Math.max(stripPaddingBottom, 0) + spaceBottom;

  lgContainer.style.paddingTop = `${baseStripPaddingTop}px`;
  lgContainer.style.paddingBottom = `${baseStripPaddingBottom}px`;

  if (isLightbox(presetPreview)) {
    if (lightboxData?.position === 'center') {
      lgContainer.style.width = `${lg.segments[0].width}px`;
      lgContainer.style.margin = '0 auto';
    }
    lgContainer.style.padding = `${
      lg.isMobile() ? LIGHTBOX_MOBILE_PADDING : LIGHTBOX_PADDING
    }px 0`;
  }

  if (applicationParameters.isInMobileEditMode()) {
    lgContainer.classList.add('lg-container-mobile');
  }

  //createMenuStrip
  if (menuStrip.enable) {
    getMenuStrip(
      menuStrip,
      lg,
      lgContainer,
      baseStripPaddingTop,
      elementWidth,
      kit,
    );
  }

  let columnsRows = lg.columns;
  if (columnsRows && lg.isMobile()) {
    columnsRows = _(columnsRows)
      .flatten()
      .map(c => [c])
      .value();
  }

  //handleColumnsRows
  if (columnsRows) {
    getColumnRows(
      columnsRows,
      lg,
      lgContainer,
      elementWidth,
      strip,
      lgPreviewsPromises,
      kit,
    );
  } else {
    getLgPreview(
      lgContainer,
      lgHeight,
      lg,
      strip,
      elementWidth,
      lgPreviewsPromises,
      kit,
    );
  }
  return stripPreview;
};

const getStrips = (
  lgDataArray: any[],
  element: HTMLElement,
  stripSizes: any[],
  solution: any,
  presetPreview: IPresetPreview,
  lgPreviewsPromises,
  kit: any,
  lightboxData?: any,
) => {
  const {
    slideShowPaddingTop,
    slideShowPaddingBottom,
  } = slideShowService.getPaddingForSlideShow(solution, presetPreview);
  let stripPreview: HTMLElement;

  _.each(solution, (lg, index: number) => {
    stripPreview = getStripWithLg(
      lg,
      lgDataArray,
      index,
      slideShowPaddingTop,
      slideShowPaddingBottom,
      element,
      stripSizes,
      solution,
      presetPreview,
      lgPreviewsPromises,
      kit,
      stripPreview,
      lightboxData,
    );
  });
};

const addArrowsToSlideShow = (
  presetPreview: IPresetPreview,
  lg: LayoutGroup,
  kit: any,
) => {
  if (
    lg.contentType !== 'ListHeader' &&
    lg.contentType !== 'ListFooter' &&
    !lg.isMobile() &&
    presetPreview.flags &&
    (presetPreview.flags as any).indexOf('slideShowPreset') > -1
  ) {
    const color = slideShowService.getNavigationColor(lg);
    return buildArrowsElement(color, kit);
  }
  return '';
};

const buildArrowsElement = (color: any, kit: any) => {
  const rgbColor = previewColors.getRGBColor(color, {
    kit,
  });
  return `<div class="slideshow-arrows" style="z-index: 1; width: 100%; position: absolute; color: ${rgbColor}; font-size: 70px;"><span class="slideshow-left-arrow" style="float: left">&#10216;</span><span class="slideshow-right-arrow" style="float: right;">&#10217;</span></div>`;
};
