import * as React from 'react';
import * as _ from 'lodash';
import { mediaGallery } from '@wix/adi-core-santa-layer';
import { MediaItemTypes } from '@wix/adi-core-types';
// import * as s from './Accordion.scss';

interface Iboundary {
  height: number;
  width: number;
}

const getStyle = ({ width, height }: Iboundary): React.CSSProperties => ({
  display: 'flex',
  flexDirection: 'row',
  height,
  width,
});

interface AccordionProps {
  boundary: Iboundary;
  value: any;
}

const getThumbnails = (value: object, boundary) => {
  return _.range(6)
    .map((v, index) => {
      const media = value[index];
      if (media) {
        const style = {
          flex: index === 1 ? '2 0 0' : '1 0 0',
          backgroundPosition: 'center',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundClip: 'content-box',
          backgroundImage: `url(${mediaGallery.itemToThumbnailUrl(
            media,
            boundary.width,
            boundary.height,
          )})`,
        };

        return <div key={index} style={style} />;
      } else {
        return null;
      }
    })
    .filter(el => el !== null);
};

export const Accordion: React.FunctionComponent<AccordionProps> = ({
  boundary,
  value,
}) => {
  value = _.filter(value, { mediaType: MediaItemTypes.Image });

  return (
    <div className="accordion-media-layout-preview" style={getStyle(boundary)}>
      {getThumbnails(value, boundary)}
    </div>
  );
};
