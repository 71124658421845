import * as React from 'react';
// import * as s from './TinyMenu.scss';

interface Iboundary {
  height: number;
  width: number;
}

interface TinyMenuProps {
  color: string;
  boundary: Iboundary;
}

const getMenuStroke = (color: string): JSX.Element => (
  <div className="tiny-menu-stroke" style={{ backgroundColor: color }} />
);

export const TinyMenu: React.FunctionComponent<TinyMenuProps> = ({
  color,
  boundary,
}) => {
  return (
    <div
      className="tiny-menu-layout-preview"
      style={{
        height: boundary.height,
        width: boundary.width,
      }}
    >
      {new Array(3).fill(0).map(() => getMenuStroke(color))}
    </div>
  );
};
