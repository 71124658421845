import * as React from 'react';
import { IPresetPreview } from '@wix/adi-core-santa-layer';
import { PresetsTransforms, Transform } from '../../utils/presetsTransformsJQ';
import { SinglePresetPreview } from '../SinglePresetPreview/SinglePresetPreview';
// import * as s from './MultiPresetPreview.scss';

interface MultiPresetPreviewState {}

interface MultiPresetPreviewProps {
  presetPreviewArray: IPresetPreview[];
  isSelected: boolean;
  onBeforeRender(): void;
  onAfterRender(): void;
  previewPosition: string;
  static: boolean;
  flags: any;
  kit: any;
  $translate: any;
}

export class MultiPresetPreview extends React.PureComponent<
  MultiPresetPreviewProps,
  MultiPresetPreviewState
> {
  private ref: HTMLElement;
  private isAfterRenderScheduled: boolean = false;
  constructor(props: MultiPresetPreviewProps) {
    super(props);
    this.state = {};
  }

  beforeRenderHandler() {
    if (this.props.onBeforeRender) {
      this.props.onBeforeRender();
    }
  }

  afterRenderHandler(): void {
    if (this.props.presetPreviewArray.length === 1) {
      this.doAfterRender();
    } else {
      if (!this.isAfterRenderScheduled) {
        this.isAfterRenderScheduled = true;
        this.doAfterRender();
      }
    }
  }

  private doAfterRender(): void {
    this.isAfterRenderScheduled = false;
    this.executeTransforms();
    if (this.props.onAfterRender) {
      this.props.onAfterRender();
    }
  }

  private executeTransforms(): void {
    if (this.props.flags) {
      const presetElements = this.ref.querySelectorAll(
        '.single-preset-preview > *',
      );
      this.props.flags
        .filter(flag => !!PresetsTransforms[flag])
        .map(transformId => PresetsTransforms[transformId])
        .forEach((transform: Transform) => transform(presetElements, null));
      //NEED TO FIX angular SCOPE INSTEAD OF NULL!!!!!
    }
  }

  render() {
    const { isSelected, previewPosition, flags, kit, $translate } = this.props;

    return (
      <div
        className="multi-preset-preview"
        ref={ref => {
          this.ref = ref;
        }}
      >
        {this.props.presetPreviewArray.map((presetPreview, i) => (
          <SinglePresetPreview
            index={i}
            key={i}
            presetPreview={presetPreview}
            isSelected={isSelected}
            onBeforeRender={() => this.beforeRenderHandler()}
            onAfterRender={() => this.afterRenderHandler()}
            previewPosition={previewPosition}
            static={this.props.static}
            flags={flags}
            kit={kit}
            $translate={$translate}
          />
        ))}
      </div>
    );
  }
}
