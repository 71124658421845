import * as React from 'react';
import { mediaGallery, previewColors } from '@wix/adi-core-santa-layer';
import ReactHtmlParser from 'react-html-parser';

interface Iboundary {
  height: number;
  width: number;
}

interface ImpressProps {
  boundary: Iboundary;
  kit: any;
  value: any;
}

const getImageStyle = (media, boundary: Iboundary): React.CSSProperties => ({
  flex: 1,
  height: '280px',
  backgroundPosition: 'center',
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  backgroundClip: 'content-box',
  padding: '10px',
  backgroundImage: `url(${mediaGallery.itemToThumbnailUrl(
    media,
    boundary.width,
    boundary.height,
  )})`,
});

const getContainerStyle = (): React.CSSProperties => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
});

const getTextContainerStyle = (): React.CSSProperties => ({
  width: '40%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
});

const getTitleStyle = (kit): React.CSSProperties => ({
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  textAlign: 'left',
  lineHeight: '27px',
  fontSize: '24px',
  fontFamily: 'proxima-nova, sans-serif',
  fontWeight: 300,
  padding: '0 10px',
  color: previewColors.getRGBColor(16 as any, { kit }),
});

const getDescStyle = (kit: any): React.CSSProperties => ({
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  textAlign: 'left',
  lineHeight: '27px',
  fontSize: '14px',
  fontFamily: 'proxima-nova, sans-serif',
  fontWeight: 300,
  padding: '0 10px',
  color: previewColors.getRGBColor(1 as any, { kit }),
});

const svgArrowLeft: string =
  '<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="12.8px" height="25.5px" viewBox="29.5 13.9 12.8 25.5" style="enable-background:new 29.5 13.9 12.8 25.5;margin: 0 30px 0 20px;" xml:space="preserve"> <path d="M41,39.5l1.2-1.3L30.5,25.4l-1.2,1.3L41,39.5z"/> <path d="M42.2,15.2L41,13.9L29.4,26.7l1.2,1.3L42.2,15.2z"/> </svg>';
const svgArrowRight: string =
  '<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="12.8px" height="25.5px" viewBox="29.5 13.9 12.8 25.5" style="enable-background:new 29.5 13.9 12.8 25.5;margin: 0 20px 0 30px;"xml:space="preserve"> <path d="M30.5,39.5l-1.2-1.3L41,25.4l1.2,1.3L30.5,39.5z"/> <path d="M29.4,15.2l1.2-1.3l11.6,12.8L41,28L29.4,15.2z"/> </svg>';

export const Impress: React.FunctionComponent<ImpressProps> = ({
  boundary,
  value,
  kit,
}) => {
  const media = value[0];

  return (
    <div className="impress-media-layout-preview" style={getContainerStyle()}>
      {ReactHtmlParser(svgArrowLeft)}
      <div style={getTextContainerStyle()}>
        <div className="title" style={getTitleStyle(kit)}>
          {media.Title}
        </div>
        <div className="desc" style={getDescStyle(kit)}>
          {media.Description}
        </div>
      </div>
      <div className="img" style={getImageStyle(media, boundary)} />
      {ReactHtmlParser(svgArrowRight)}
    </div>
  );
};
