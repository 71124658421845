import * as React from 'react';
import * as _ from 'lodash';
import { MediaItemTypes } from '@wix/adi-core-types';
import { VideoBg } from './VideoBg/VideoBg';

interface StripVideoProps {
  layout: any;
  value: any;
  stripWidth: number;
}

export const StripVideo: React.FunctionComponent<StripVideoProps> = ({
  layout,
  value,
  stripWidth,
}) => {
  const videoMediaItem = _.find(value, { mediaType: MediaItemTypes.Video });
  const { height } = layout;

  return (
    <div className="strip-media-layout-preview" style={{ height }}>
      <VideoBg mediaItem={videoMediaItem} width={stripWidth} height={height} />
    </div>
  );
};
