import * as React from 'react';
import * as _ from 'lodash';
import { mediaGallery } from '@wix/adi-core-santa-layer';
import { MediaItemTypes } from '@wix/adi-core-types';
// import * as s from './Masonry.scss';

interface Iboundary {
  height: number;
  width: number;
}

interface MasonryProps {
  boundary: Iboundary;
  value: any;
}

const getBackgroundImages = (amountImages: number, value, boundary) => {
  return _.range(amountImages).map((el, i) => {
    const media =
      value[i] || value[i - value.length] || value[i - 2 * value.length];
    return `url(${mediaGallery.itemToThumbnailUrl(
      media,
      boundary.width,
      boundary.height,
    )})`;
  });
};

const imageDefaultStyle: React.CSSProperties = {
  backgroundPosition: 'center',
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  backgroundClip: 'content-box',
  padding: '5px',
};

const getContainerStyle = (boundary: Iboundary): React.CSSProperties => ({
  display: 'flex',
  flexDirection: 'row',
  height: boundary.height,
  width: boundary.width,
});

export const Masonry: React.FunctionComponent<MasonryProps> = ({
  boundary,
  value,
}) => {
  value = _.filter(value, { mediaType: MediaItemTypes.Image });
  const backgroundImages = getBackgroundImages(5, value, boundary);

  return (
    <div
      className="masonry-media-layout-preview"
      style={getContainerStyle(boundary)}
    >
      <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
        <div
          className="img"
          style={{
            ...imageDefaultStyle,
            backgroundImage: backgroundImages[0],
            flex: 1,
          }}
        />
        <div
          className="img"
          style={{
            ...imageDefaultStyle,
            backgroundImage: backgroundImages[1],
            flex: 1,
          }}
        />
      </div>
      <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
        <div
          className="img"
          style={{
            ...imageDefaultStyle,
            backgroundImage: backgroundImages[2],
            flex: 1,
          }}
        />
        <div
          className="img"
          style={{
            ...imageDefaultStyle,
            backgroundImage: backgroundImages[3],
            flex: 2,
          }}
        />
        <div
          className="img"
          style={{
            ...imageDefaultStyle,
            backgroundImage: backgroundImages[4],
            flex: 1,
          }}
        />
      </div>
    </div>
  );
};
