import * as React from 'react';
import {
  IPresetPreview,
  applicationParameters,
} from '@wix/adi-core-santa-layer';
import { getHookByNode, removeAllChildNodes } from './utils';
import { renderComponents } from './SinglePresetPreviewComponents/SinglePresetPreviewCompontents';
// import * as s from './SinglePresetPreview.scss';

export const COLUMN_ALIGNMENT = {
  0: 'flex-start',
  50: 'center',
  100: 'flex-end',
};

export const WIDTH = 1200;
export const MOBILE_WIDTH = 320;
export const PAGE_WIDTH = applicationParameters.isInMobileEditMode()
  ? applicationParameters.mobilePageWidth
  : applicationParameters.desktopPageWidth;

interface SinglePresetPreviewState {}

interface SinglePresetPreviewProps {
  presetPreview: IPresetPreview;
  isSelected: boolean;
  onBeforeRender(): void;
  onAfterRender(): void;
  previewPosition: string;
  static: boolean;
  flags: any;
  kit: any;
  index: number;
  $translate: any;
}

//NOT IMPLEMENTED YET AS DID NOT SEE ANY USE CASE

export const UPDATE_ALL_PREVIEWS_KEY = 'update-all-previews';
export const UPDATE_SELECTED_PREVIEWS_KEY = 'update-selected-preview';
// this.$scope.$on(UPDATE_ALL_PREVIEWS_KEY, () => this.reRender(this.presetPreview));
// this.$scope.$on(UPDATE_SELECTED_PREVIEWS_KEY, () => {
//   if (this.isSelected) {
//     this.reRender(this.presetPreview);
//   }
// });

export class SinglePresetPreview extends React.PureComponent<
  SinglePresetPreviewProps,
  SinglePresetPreviewState
> {
  private readonly presetWidth: number;
  private components: HTMLElement;
  private readonly lgPreviewsPromises: Promise<void>[] = [];
  private ref: HTMLElement;
  constructor(props: SinglePresetPreviewProps) {
    super(props);
    this.state = {};
    this.presetWidth = applicationParameters.isInMobileEditMode()
      ? MOBILE_WIDTH
      : WIDTH;
  }

  isComponentReady() {
    if (!this.ref) {
      return false;
    }

    if (!this.props.presetPreview) {
      return false;
    }

    if (this.props.presetPreview && !this.props.presetPreview.solution) {
      console.log('preset with no solution');
      return false;
    }

    return true;
  }

  getHook(): string {
    const node = this.props.presetPreview && this.props.presetPreview.node;
    return node ? getHookByNode(node) : 'unavailable';
  }

  renderInnerComponents() {
    if (!this.isComponentReady()) {
      return;
    }

    const { onBeforeRender, presetPreview, kit, $translate } = this.props;

    onBeforeRender();
    this.components = renderComponents(
      presetPreview,
      this.presetWidth,
      this.lgPreviewsPromises,
      kit,
      $translate,
    );
    Promise.all(this.lgPreviewsPromises).then(() => {
      if (this.ref) {
        if (this.ref.hasChildNodes()) {
          removeAllChildNodes(this.ref);
        }
        this.ref.append(this.components);
        this.props.onAfterRender();
      }
    });
  }

  componentDidMount() {
    this.renderInnerComponents();
  }

  componentDidUpdate() {
    this.renderInnerComponents();
  }

  getZIndex() {
    return 100 - this.props.index;
  }

  render() {
    return (
      <div
        style={{ zIndex: this.getZIndex(), position: 'relative' }}
        className="single-preset-preview"
        data-hook={this.getHook()}
        ref={ref => {
          this.ref = ref;
        }}
      />
    );
  }
}
