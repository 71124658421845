import * as React from 'react';
import { LayoutElement, isLightboxType } from '@wix/adi-core-santa-layer';
import { ElementTypes, ElementSubTypes } from '@wix/adi-core-types';
import { Media } from './LayoutPreviewComponents/Media/Media';
import { Line } from './LayoutPreviewComponents/Line/Line';
import { Container } from './LayoutPreviewComponents/Container/Container';
import { Social } from './LayoutPreviewComponents/Social/Social';
import { Icon } from './LayoutPreviewComponents/Icon/Icon';
import { Menu } from './LayoutPreviewComponents/Menu/Menu';
import { Buttons } from './LayoutPreviewComponents/Buttons/Buttons';
import { Text } from './LayoutPreviewComponents/Text/Text';
import { ContactForm } from './LayoutPreviewComponents/ContactForm/ContactForm';
import { SubscribeFooter } from './LayoutPreviewComponents/Media/MediaComponents/SubscribeFooter/SubscribeFooter';

// import * as s from './LayoutPreview.scss';

interface Iboundary {
  height: number;
  width: number;
}

interface LayoutPreviewProps {
  boundary: Iboundary;
  layout: any;
  le: LayoutElement;
  kit: any;
  width: number;
}

export const LayoutPreview: React.FunctionComponent<LayoutPreviewProps> = ({
  layout,
  le,
  width,
  boundary,
  kit,
}) => {
  const argValue = le.getArgValue(layout.argIndex);
  const value = le.getLayoutValue(layout, argValue);

  if (le.isMediaType(layout) && layout.subType !== ElementSubTypes.Login) {
    return <Media {...{ layout, value, le, width, boundary, kit }} />;
  }

  if (layout.type === ElementTypes.Form) {
    // Subscribe in footer (NO TEST COVERAGE)
    // Subscribe in Lightbox workaround
    if (
      le.isHeader ||
      isLightboxType(le.containingSegment?.contentElement?.type)
    ) {
      return <SubscribeFooter {...{ le, layout, kit, boundary }} />;
    }
    return <ContactForm {...{ le, layout, kit, boundary }} />;
  }

  if (layout.type === ElementTypes.Menu) {
    return <Menu {...{ layout, le, boundary, kit }} />;
  }

  if (layout.type === ElementTypes.Social) {
    return <Social {...{ layout, le, boundary, value, kit }} />;
  }

  if (
    layout.type === ElementTypes.ShoppingCart ||
    layout.type === ElementTypes.Social ||
    layout.subType === ElementSubTypes.Login
  ) {
    return <Icon {...{ boundary }} />;
  }

  if (layout.type === ElementTypes.Container) {
    return <Container {...{ layout, le, kit }} />;
  }

  if (layout.type === ElementTypes.Line) {
    return <Line {...{ layout, le, kit }} />;
  }

  if (layout.type === ElementTypes.Button) {
    return <Buttons {...{ argValue, layout, le, value, kit }} />;
  }

  if (layout.type === ElementTypes.Text) {
    return <Text {...{ layout, le, value, kit }} />;
  }

  console.log('COMPONENT NOT FOUND IN LAYOUT PREVIEW');
  return <div />;
};
