import * as React from 'react';
import * as _ from 'lodash';
import {
  designKit,
  previewColors,
  LayoutElement,
  socialLinkManager,
  Flags,
} from '@wix/adi-core-santa-layer';

const SVG_WIDTH = 25;
const SVG_HEIGHT = 25;
const SVG_MARGIN = 10;

interface SocialProps {
  layout: any;
  le: LayoutElement;
  kit: any;
  boundary;
  value: any;
}

const getContainerStyle = (boundary, le): React.CSSProperties => {
  const lss = le.getLayoutSelectionStore();
  const menuAlignment = lss ? lss.menuOverridesManager.alignment() : 'center';

  return {
    display: 'flex',
    height: boundary.height,
    width: boundary.width,
    justifyContent:
      menuAlignment === 'left'
        ? 'flex-start'
        : menuAlignment === 'right'
        ? 'flex-end'
        : 'center',
  };
};

const getColor = (le, kit, layout): string => {
  let color;
  if (Flags.designKit) {
    let bgColor;
    if (le.containingLG.isHeader()) {
      // header or footer
      const origLayout = le.getLayoutBySubType(layout.subType);
      if (le.isLayoutInMenuStripArea(origLayout)) {
        bgColor = le.containingLG.getLgData().menuStrip.color;
      } else {
        if (le.containingLG.isSiteHeader()) {
          bgColor = '{' + designKit.getHeaderBg([le.containingLG]) + '}';
        } else {
          bgColor = '{' + designKit.getFooterBg([le.containingLG]) + '}';
        }
      }
    } else {
      bgColor = designKit.getBgColor(le.containingLG);
    }

    if (bgColor) {
      color = previewColors.getRGBColor(
        designKit.invertToBrightestColor(bgColor),
        {
          kit,
        },
      );
    }
  }
  return color;
};

const getSocialIcon = (icon, color, margin = false): JSX.Element => {
  const svg = socialLinkManager
    .getPlatformByName(icon.platform)
    .svg(color, SVG_WIDTH, SVG_HEIGHT);
  return (
    <div
      key={icon.platform}
      style={{ height: SVG_HEIGHT, marginRight: margin ? SVG_MARGIN : 0 }}
      dangerouslySetInnerHTML={{ __html: svg }}
    />
  );
};

const getSocialIcons = (
  icons: any[],
  le: LayoutElement,
  kit: any,
  layout: any,
) => {
  const color = getColor(le, kit, layout);
  const lastIcon = getSocialIcon(_.last(icons), color, false);
  const remainingIcons = _.initial(icons).map(icon =>
    getSocialIcon(icon, color, true),
  );
  return [...remainingIcons, lastIcon];
};

export const Social: React.FunctionComponent<SocialProps> = ({
  layout,
  le,
  boundary,
  value,
  kit,
}) => (
  <div style={getContainerStyle(boundary, le)}>
    {getSocialIcons(value, le, kit, layout)}
  </div>
);
