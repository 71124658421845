import * as React from 'react';
import * as _ from 'lodash';
import { LayoutElement, mediaGallery } from '@wix/adi-core-santa-layer';
import { MediaItemTypes, ElementSubTypes } from '@wix/adi-core-types';

interface VideoState {
  style: React.CSSProperties;
}

interface VideoProps {
  boundary: Iboundary;
  layout: any;
  le: LayoutElement;
  value;
}

interface Iboundary {
  height: number;
  width: number;
}

export class Video extends React.Component<VideoProps, VideoState> {
  mediaItem: any;
  constructor(props: VideoProps) {
    super(props);
    this.state = {
      style: this.getInitialStyle(),
    };
    this.mediaItem = this.getMediaItem();
  }

  componentDidMount() {
    this.setBackgroundImage();
  }

  getMediaItem() {
    const { le, layout, value } = this.props;
    const override =
      le.overrides && le.overrides.media && le.overrides.media[layout.argIndex];
    const videoType =
      override.type === 'video'
        ? MediaItemTypes.Video
        : MediaItemTypes.ExtVideo;
    const mediaItem = _.find(value, function(item: any) {
      return item.mediaType === videoType;
    });
    return mediaItem;
  }

  getInitialStyle() {
    const { boundary } = this.props;
    const style: React.CSSProperties = {
      position: 'relative',
      backgroundPosition: 'center',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      height: boundary.height,
      width: boundary.width,
    };

    return style;
  }

  setBackgroundImage(): void {
    const { boundary, layout, le } = this.props;
    const mediaItem = this.mediaItem;

    const override =
      le.overrides && le.overrides.media && le.overrides.media[layout.argIndex];

    const imageLayout = le && le.getLayoutBySubType(ElementSubTypes.Image);
    const crop =
      le.containingSegment &&
      le.containingSegment.node &&
      imageLayout &&
      le.containingSegment.node.getCropOfImage(
        imageLayout.argIndex,
        mediaItem.relativeUri,
        imageLayout,
      );

    const fit = _.get(override, ['properties', 'displayMode']) === 'full';

    const options: any = {
      crop,
      fit,
    };

    const backgroundImage:
      | string
      | Promise<string | undefined> = mediaGallery.itemToThumbnailUrl(
      mediaItem,
      boundary.width,
      boundary.height,
      options,
    );

    if ((backgroundImage as any).then) {
      (backgroundImage as any).then(backgroundImageUrl => {
        this.setBackgroundImageStyle(backgroundImageUrl, fit);
      });
    } else {
      this.setBackgroundImageStyle(backgroundImage, fit);
    }
  }

  setBackgroundImageStyle(backgroundImageUrl: string, fit) {
    const backgroundImage = `url("${backgroundImageUrl}")`;
    const newStyle = {
      ...this.state.style,
      backgroundImage,
      backgroundSize: fit ? 'contain' : 'cover',
    };
    this.setState({ style: newStyle });
  }

  render() {
    return (
      <div className="media-layout-preview" style={this.state.style}>
        <div>
          <div
            className="circle"
            style={{
              width: '100px',
              height: '100px',
              border: '5px solid #ffffff',
              borderRadius: '50%',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              position: 'absolute',
              backgroundColor: 'rgba(0,0,0,.5)',
            }}
          />
          <div
            className="triangle"
            style={{
              width: '0%',
              height: '0%',
              borderTop: '20px solid transparent',
              borderLeft: '30px solid #ffffff',
              borderBottom: '20px solid transparent',
              top: '50%',
              left: '50%',
              transform: 'translate(-35%, -50%)',
              position: 'absolute',
            }}
          />
        </div>
      </div>
    );
  }
}
