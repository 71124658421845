import * as React from 'react';
import * as _ from 'lodash';
import classNames from 'classnames';
import {
  IPresetPreview,
  applicationParameters,
} from '@wix/adi-core-santa-layer';
import { MultiPresetPreview } from '../MultiPresetPreview/MultiPresetPreview';
import * as s from './PresetPreview.scss';
import { removePx } from '../SinglePresetPreview/utils';
const MOBILE_SCALE = 0.675;

interface PresetPreviewState {
  wrapWidth: number;
  wrapHeight: number;
}

interface PresetPreviewProps {
  presetPreview: IPresetPreview;
  isSelected?: boolean;
  scale?: number;
  onBeforeRender?(): void;
  onAfterRender?(): void;
  previewPosition?: string;
  static?: boolean;
  flags?: any;
  kit?: any;
  $translate?: any;
}

export class PresetPreview extends React.PureComponent<
  PresetPreviewProps,
  PresetPreviewState
> {
  private refInnerWrapper: HTMLElement;
  private effectiveScale: number;
  private outerWrapperRef: HTMLElement;
  constructor(props: PresetPreviewProps) {
    super(props);
  }

  componentWillMount() {
    this.calculateEffectiveScale();
  }

  beforeRenderHandler() {
    if (this.props.onBeforeRender) {
      this.props.onBeforeRender();
    }
  }

  afterRenderHandler(): void {
    this.calculateWrapperSize();
    if (this.props.onAfterRender) {
      this.props.onAfterRender();
    }
  }

  private calculateEffectiveScale() {
    const scale = this.props.scale === undefined ? 1 : this.props.scale;
    this.effectiveScale = applicationParameters.isInMobileEditMode()
      ? MOBILE_SCALE
      : scale;
  }

  private calculateWrapperSize() {
    const innerWrapper: HTMLElement = this.refInnerWrapper;
    const wrapWidth = this.effectiveScale * innerWrapper.offsetWidth;
    const wrapHeight = this.effectiveScale * innerWrapper.offsetHeight;

    if (
      removePx(this.outerWrapperRef.style.height) !== wrapHeight ||
      removePx(this.outerWrapperRef.style.width) !== wrapWidth
    ) {
      this.outerWrapperRef.style.width = `${wrapWidth}px`;
      this.outerWrapperRef.style.height = `${wrapHeight}px`;
    }
  }

  private getPresetPreviewArray() {
    const presetPreview = this.props.presetPreview;
    return _.isArray(presetPreview) ? presetPreview : [presetPreview];
  }

  render() {
    const { isSelected, previewPosition, flags, kit, $translate } = this.props;
    const presetId =
      this.props.presetPreview &&
      this.props.presetPreview.preset &&
      this.props.presetPreview.preset.id;

    return (
      <div className={classNames('preset-preview', s.root)}>
        <div
          className={'preset-preview-outer-wrapper'}
          {...{
            presetid: presetId,
          }}
          ref={ref => (this.outerWrapperRef = ref)}
        >
          <div
            className={'preset-preview-inner-wrapper'}
            style={{
              transform: `matrix(${this.effectiveScale}, 0, 0, ${this.effectiveScale}, 0, 0)`,
            }}
            ref={ref => {
              this.refInnerWrapper = ref;
            }}
          >
            <MultiPresetPreview
              presetPreviewArray={this.getPresetPreviewArray()}
              isSelected={isSelected}
              onBeforeRender={() => this.beforeRenderHandler()}
              onAfterRender={() => this.afterRenderHandler()}
              previewPosition={previewPosition}
              static={this.props.static}
              flags={flags}
              kit={kit}
              $translate={$translate}
            />
          </div>
        </div>
      </div>
    );
  }
}
