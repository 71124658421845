import * as _ from 'lodash';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { catalog, themeChooser } from '@wix/adi-core-santa-layer';

export const convertToNumber = (str: string): number =>
  typeof str !== 'string' ? 0 : Number(str.replace('px', ''));

export const getCatalogStyleDefinition = (styleId: string): any => {
  if (styleId.startsWith('catalog-')) {
    return _.get(catalog, styleId.split('-').slice(1, 3));
  }
  const activeTheme = themeChooser.getActiveTheme('color');
  return activeTheme.definition.styles[styleId];
};

export const getDomElementFromReactComponent = (component, props) => {
  const element = getDomElementFromReactComponentParent(component, props);
  return element.children && element.children[0];
};

export const getDomElementFromReactComponentParent = (component, props) => {
  const element = document.createElement('div');
  const reactElement = React.createElement(component, props);
  ReactDOM.render(reactElement, element);
  return element;
};
