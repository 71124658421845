import * as React from 'react';
import { LayoutElement, mediaOverride } from '@wix/adi-core-santa-layer';
import { ElementTypes } from '@wix/adi-core-types';
import { Accordion } from './MediaComponents/Accordion/Accordion';
import { Masonry } from './MediaComponents/Masonry/Masonry';
import { Slideshow } from './MediaComponents/Slideshow/Slideshow';
import { Impress } from './MediaComponents/Impress/Impress';
import { Thumbnails } from './MediaComponents/Thumbnails/Thumbnails';
import { Collage } from './MediaComponents/Collage/Collage';
import { Slider } from './MediaComponents/Slider/Slider';
import { StripVideo } from './MediaComponents/StripVideo/StripVideo';
import { Strip } from './MediaComponents/Strip/Strip';
import { Grid } from './MediaComponents/Grid/Grid';
import { FreeStyle } from './MediaComponents/FreeStyle/FreeStyle';
import { Map } from './MediaComponents/Map/Map';
import { Html } from './MediaComponents/Html/Html';
import { Video } from './MediaComponents/Video/Video';
import { Image } from './MediaComponents/Image/Image';

interface Iboundary {
  height: number;
  width: number;
}

const getStyle = ({ width, height }: Iboundary): React.CSSProperties => ({
  height,
  width,
});

interface MediaProps {
  boundary: Iboundary;
  layout: any;
  le: LayoutElement;
  kit: any;
  value: any;
  width: number;
}

const getOverride = (le: LayoutElement, layout: any) =>
  le.overrides && le.overrides.media && le.overrides.media[layout.argIndex];
const getStripWidth = (le: LayoutElement, width: number) =>
  le.isMobile() ? 320 : width;

export const Media: React.FunctionComponent<MediaProps> = ({
  boundary,
  layout,
  le,
  kit,
  value,
  width,
}) => {
  const override: any = getOverride(le, layout);
  const stripWidth = getStripWidth(le, width);

  if (override && override.name) {
    if (override.name === 'Accordion') {
      return <Accordion {...{ value, boundary }} />;
    }

    if (override.name === 'Masonry') {
      return <Masonry {...{ value, boundary }} />;
    }

    if (override.name === 'Slideshow') {
      return <Slideshow {...{ value, boundary }} />;
    }

    // Impress (NO TEST COVERAGE)
    if (override.name === 'Impress') {
      return <Impress {...{ value, boundary, kit }} />;
    }

    if (override.name === 'Thumbnails') {
      return <Thumbnails {...{ value, boundary, override }} />;
    }

    //Fullwidth Collage (NO TEST COVERAGE)
    if (override.name.indexOf('Collage') >= 0) {
      return <Collage {...{ value, boundary, override }} />;
    }

    // Slider (NO TEST COVERAGE)
    if (override.name === 'Slider' || override.name === 'Strip Showcase') {
      return <Slider {...{ value, boundary, override }} />;
    }

    if (override.name === 'Strip Video') {
      return <StripVideo {...{ value, layout, stripWidth }} />;
    }

    if (override.name.indexOf('Grid') >= 0) {
      return <Grid {...{ value, boundary, override, le }} />;
    }

    // Freestyle (NO TEST COVERAGE)
    if (override.name.indexOf('Freestyle') >= 0) {
      return <FreeStyle {...{ value, boundary }} />;
    }

    // TEMPORARY NOT RENDERING VIDEO IMAGE UNTIL REFACTOR COMPLETED
    if (override && override.name === 'video') {
      return <Video {...{ value, boundary, layout, le }} />;
    }
  }

  if (
    (override && override.name === 'Image') ||
    (!override && layout && layout.subType === 'image')
  ) {
    return <Image {...{ value, boundary, layout, le, kit }} />;
  }

  if (mediaOverride.isStripType(le)) {
    return <Strip {...{ value, override, layout, le, stripWidth }} />;
  }

  if (layout) {
    if (layout.type === ElementTypes.Map) {
      return <Map {...{ boundary }} />;
    }

    //Html (NO TEST COVERAGE)
    if (layout.type === ElementTypes.Html) {
      return <Html {...{ boundary }} />;
    }
  }

  console.log('COMPONENT NOT FOUND');
  return <div style={getStyle(boundary)} />;
};
