import * as React from 'react';
// import * as s from './Icon.scss';

interface Iboundary {
  height: number;
  width: number;
}

const getStyle = ({ width, height }: Iboundary): React.CSSProperties => ({
  height,
  width,
});

interface IconProps {
  boundary: Iboundary;
}

export const Icon: React.FunctionComponent<IconProps> = ({ boundary }) => (
  <div style={getStyle(boundary)} />
);
